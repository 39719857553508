import React, { useState } from "react";
import { Accordion as BootstrapAccordion } from "react-bootstrap";
import AccordionItem from "./AccordionItem";

export default function Accordion({ thirdPartyDetails = [] }) {
  const [activeIndex, setActiveIndex] = useState("0"); // 기본적으로 첫 번째 아이템 열기

  const toggleAccordion = (index) => {
    // 현재 열린 항목을 클릭하면 닫고, 다른 항목을 클릭하면 해당 항목을 엶
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <BootstrapAccordion activeKey={activeIndex}>
      {thirdPartyDetails.map((item, index) => (
        <AccordionItem
          key={index}
          eventKey={index.toString()}
          provConsentNm={item.provConsentNm}
          consentRcvNm={item.consentRcvNm}
          pvsnArtclCn={item.pvsnArtclCn}
          provConsentPurpose={item.provConsentPurpose}
          provConsentDtime={item.provConsentDtime}
          isOpen={activeIndex === index.toString()}
          onClick={() => toggleAccordion(index.toString())}
        />
      ))}
    </BootstrapAccordion>
  );
}
