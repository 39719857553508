// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-conatiner {
  display: flex;
  width: 100%;
}

.label-title {
  display: flex;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/LabelComponent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".label-conatiner {\n  display: flex;\n  width: 100%;\n}\n\n.label-title {\n  display: flex;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
