// import React from "react";
// import { Accordion as BootstrapAccordion } from "react-bootstrap";
// import "./AccordionStyle.css";

// export default function AccordionItem({
//   title,
//   recipient,
//   sharedData,
//   eventKey,
// }) {

//   // console.log("sharedData", sharedData);
//   const trimmedTitle =  title // title.split(/를 위한|을 위한/)[0];
//   const sharedDataText = sharedData || "전송 항목 없음"; // null 체크

//   return (
//     <BootstrapAccordion.Item
//       eventKey={eventKey}
//       className="custom-accordion-item"
//     >
//       <BootstrapAccordion.Header>
//         <span>{trimmedTitle}</span>
//       </BootstrapAccordion.Header>
//       <BootstrapAccordion.Body>
//         <div className="provider-table-container">
//           <table className="provider-table">
//             <colgroup>
//               <col style={{ width: "40%" }} />
//               <col style={{ width: "60%" }} />
//             </colgroup>
//             <thead>
//               <tr>
//                 <th>제공받는 자</th>
//                 <th>전송 항목</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>{recipient || "제공받는 자 없음"}</td>
//                 <td>{sharedDataText}</td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//       </BootstrapAccordion.Body>
//     </BootstrapAccordion.Item>
//   );
// }


import React from "react";
import { Accordion as BootstrapAccordion, Table } from "react-bootstrap";
import { formatDate } from "../../utils/utils";
export default function AccordionItem({
  provConsentNm,
  consentRcvNm,
  pvsnArtclCn,
  provConsentPurpose,
  provConsentDtime,
  consentTime,
  eventKey,
  onClick
}) {
  // const trimmedTitle =  provConsentNm;
  return (
    <BootstrapAccordion.Item eventKey={eventKey}>
      <BootstrapAccordion.Header  onClick={onClick}>{provConsentNm}</BootstrapAccordion.Header>
      <BootstrapAccordion.Body>
      <h5>제3자 동의한 일자 : {formatDate(provConsentDtime) || "동의 일시 없음"} </h5>
      <h5>이용목적 : {provConsentPurpose || "이용 목적 없음"}</h5>
      <Table bordered>
          <thead>
            <tr>
              <th>제공받는 자</th>
              <th>제공 항목</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{consentRcvNm || "정보 수신 기관 없음"}</td>
              <td>{pvsnArtclCn?.join(", ") || "전송 항목 없음"}</td>
            </tr>
          </tbody>
        </Table>

      </BootstrapAccordion.Body>
    </BootstrapAccordion.Item>
  );
}
