import React from "react";
//./CardStyle.css
// import { getServiceTag } from "../../utils/utils"; // 유틸 함수 불러오기
import { formatDate } from "../../utils/utils";
import "./CardStyle.css";
export default function CardRevokeFront({
  // title,
  // serviceProvider,
  // dataProvider,
  // dataProviders,
  // revokedAt,
  // dataProvided
  card
}) {
  console.log("cardcardcardcardcard",card);
  // 필터링된 데이터 프로바이더
  // const filteredDataProviders = card.data_providers?.filter(
  //   (provider) =>
  //     provider.trsm_rqust_stts_cd === "0" || provider.trsm_rqust_stts_cd === "3"
  // ) || [];
  const revokedProviders = card.data_providers.filter(
    (provider) => provider.trsm_rqust_stts_cd === "1"
  );
  // if(dataProviders != null){
  //   dataProviders = dataProviders.split(",")
  // }
  // const providedDataText =
  //   dataProviders && dataProviders.length > 0
  //     ? dataProviders.length > 1
  //       ? `${dataProviders[0]} 외 ${dataProviders.length - 1}건`
  //       : dataProviders[0]
  //     : "제공 항목 없음";

  // dataProvided 값을 처리하는 로직 추가
  const formattedDataProvided = card.data_provided
    ? card.data_provided.split(" ").join(" ") 
    : "제공 항목 없음";

  // console.log(
  //   "CardRevokeFrontCardRevokeFrontCardRevokeFrontCardRevokeFrontCardRevokeFront",
  //   dataProviders
  // );

  return ( 
    <div className="panel card-item p-8 card-front panel-revoke" >
    {/* 카드 타이틀 */}                                    
    <span class="event-label event-label-revoke">철회 완료</span> 
    <div className="card-item-content">
      <div className="card-item-body mt-0">
        <h4
          // href="javascript:;"
          // className="stretched-link service-name"
          // onClick={handleShowModal}
        >
          {card.title}
        </h4>
        {/* <div className="inst-name">{formatDate(card.last_consent_date)}</div> */}
        <div className="service-desc"> <span className="card-item-label">[정보수신자] : </span>아이즈 비전</div>
        <div className="service-desc"> <span className="card-item-label">[정보전송자] : </span>sk 텔레콤</div>
        <div className="service-desc"> <span className="card-item-label">[철회날짜] : </span> 2024.01.31 </div>
        <div className="service-desc"> <span className="card-item-label">[철회항목] : </span>기기 정보, 이름, 통화, 내역 번호</div>
        {/* <div className="service-desc"> <span className="card-item-label">[정보수신자] : </span>{card.serviceProvider}</div>
        <div className="service-desc"> <span className="card-item-label">[정보전송자] : </span> {card.data_providers.data_provider}</div>
        <div className="service-desc"> <span className="card-item-label">[철회날짜] : </span> {formatDate(card.revoked_at)}</div>
        <div className="service-desc"> <span className="card-item-label">[철회항목] : </span> {formattedDataProvided}</div> */}
      </div>
    </div>

    {/* 태그 및 버튼 */}
    {/* <div className="card-item-tag-btn">
      <div className="tags">
        {card.fldNm && (
          <span className="btn btn-light btn-service-tag category">
            #{card.fldNm}
          </span>
        )}
          <>
            <span className="btn btn-light btn-service-tag">#철회 완료</span>
           
          </>
        </div>
    </div> */}
  </div>
    // <div className="panel card-item p-8 card-front" >
    //   {/* 카드 타이틀 */}
    //   <div className="card-item-content">
    //     <div className="card-item-body mt-0">
    //       <h4
    //         // href="javascript:;"
    //         // className="stretched-link service-name"
    //         // onClick={handleShowModal}
    //       >
    //         {card.title}
    //       </h4>
    //       <div className="inst-name">{formatDate(card.last_consent_date)}</div>
    //       <div className="service-desc"> <span className="card-item-label">[정보수신자] : </span>{card.serviceProvider}</div>
    //       <div className="service-desc"> <span className="card-item-label">[정보전송자] : </span> {}</div>
    //     </div>
    //   </div>

    //   {/* 태그 및 버튼 */}
    //   <div className="card-item-tag-btn">
    //     <div className="tags">
    //       {card.fldNm && (
    //         <span className="btn btn-light btn-service-tag category">
    //           #{card.fldNm}
    //         </span>
    //       )}

    //     </div>
       
    //   </div>
    // </div>
    // <div className="card-sub-container">
    //   {/* 타이틀 */}
    //   <div className="card-title-container">
    //     <p>{title}</p>
    //     <div className="card-underline"></div>
    //   </div>
    //   {/* 카드 내용 */}
    //   <div className="card-content-container card-revoke-content-container">
    //     <ul className="card-content-list">
    //       <li>
    //         <span className="card-item-label">[정보수신자]</span>
    //         <span>{serviceProvider}</span>
    //       </li>
    //       <li>
    //         <span className="card-item-label">[정보전송자]</span>
    //         <span>{dataProvider}</span>
    //       </li>
    //       <li>
    //         <span className="card-item-label">[철회날짜]</span>
    //         <span>{formatDate(card.revoked_at)} </span>
    //       </li>
    //       <li>
    //         <span className="card-item-label">[철회항목]</span>
    //         <span>{formattedDataProvided} </span>
    //       </li>
    //     </ul>
    //     <div className="card-tag-container">
    //       <div className="card-tag card-tag-revoke">#철회 완료</div>
    //     </div>
    //   </div>
    // </div>
  );
}
