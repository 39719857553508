import React, { useState } from "react";
// import RevokedCardSlider from "../../components/CardSlider/RevokedCardSlider";
// import ChartAndCardContainer from "../../components/Chart/ChartAndCardContainer";
import Accordion from "react-bootstrap/Accordion"; // react-bootstrap의 Accordion 임포트
import Navbar from '../components/Navbar';
import RevokedCardSlider from "../components/CardSlider/RevokedCardSlider";
import ChartAndCardContainer from "../components/Chart/ChartAndCardContainer";
import NoDataMessage from "../error/NoDataMessage";
import "./mainStyle.css"
// 날짜 범위 계산 함수 (오늘 기준)
const getDateRange = (days) => {
  const today = new Date();
  today.setDate(today.getDate());
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - days);

  const formatDate = (date) =>
    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  return {
    startDate: formatDate(startDate),
    endDate: formatDate(today),
  };
};

const isDataAvailable = (data) => data && data.length > 0;

  
function Main({
  recentServices,
  activeServices,
  revokedServices,
  // handleMobileListClick,
  isLegendSelected,
  // setIsLegendSelected
  }) {

    
const [activeKey, setActiveKey] = useState("0");
const [sortOrder, setSortOrder] = useState("recent");


const handleAccordionToggle = (key) => {

  setActiveKey(key);
};  

  return (
        <Accordion
      activeKey={activeKey}
      onSelect={(eventKey) => handleAccordionToggle(eventKey)}
      className="services-accordion"
    >
      <Accordion.Item
        eventKey="0"
        className={`services-accordion-item ${
          activeKey === "0" ? "active" : ""
        }`}
      >
        <Accordion.Header className="services-accordion-header">
          <div className="d-flex flex-column flex-md-row align-items-centers ">
            <p className="me-3 mb-0">
              최근 전송요구한 서비스
            </p>
            <p className="services-accordion-date mb-0">  (조회 기간 : {getDateRange(30).startDate} ~ 
            {getDateRange(365).endDate})</p>
          </div>
        </Accordion.Header>
        <Accordion.Body className="services-accordion-body">
       
           <div className="card-slider-info-title  mb-3">
              <span><i className="icon-login-info"></i> 오늘 기준 1개월(30일)이전 정보조회</span>
            </div>
            {!isDataAvailable(recentServices) ? (
            <>
              <NoDataMessage />
              <ChartAndCardContainer
                  recentServices={recentServices}
                  activeTab="recent"
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                  isLegendSelected={isLegendSelected}
                  // setIsLegendSelected={setIsLegendSelected}
                  // handleMobileListClick={handleMobileListClick}
              />
            </>
          ) : (
            <>
         
             <div className="card-slider-info-title  mb-3">
              {/* <span><i className="icon-login-info"></i> 오늘 기준 1개월(30일)이전 정보조회</span> */}
            </div>
            <ChartAndCardContainer
                recentServices={recentServices}
                activeTab="recent"
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                isLegendSelected={isLegendSelected}
                // setIsLegendSelected={setIsLegendSelected}
                // handleMobileListClick={handleMobileListClick}
            />
            </>
            
          )}
        </Accordion.Body>

      </Accordion.Item>

      <Accordion.Item
        eventKey="1"
        className={`services-accordion-item ${
          activeKey === "1" ? "active" : ""
        }`}
      >
        <Accordion.Header className="services-accordion-header">
        <div className="d-flex flex-column flex-md-row align-items-centers ">
            <p className="me-3 mb-0">
              이용 중 서비스
            </p>
            <p className="services-accordion-date mb-0">(조회 기간 : {getDateRange(365).startDate} ~ 
            {getDateRange(365).endDate})</p>
          </div>
        </Accordion.Header>
        <Accordion.Body className="services-accordion-body">
        <div className="card-slider-info-title  mb-3">
              <span><i className="icon-login-info"></i> 오늘 기준 1년 (365일)이전 정보조회</span>
            </div>

            {!isDataAvailable(activeServices) ? (
            <>
              <NoDataMessage />
              <ChartAndCardContainer
                activeServices={activeServices}
                activeTab="active"
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                isLegendSelected={isLegendSelected}
                // setIsLegendSelected={setIsLegendSelected}
                // handleMobileListClick={handleMobileListClick}
              />
            </>
          ) : (
            <>
         
             <div className="card-slider-info-title  mb-3">
              {/* <span><i className="icon-login-info"></i> 오늘 기준 1개월(30일)이전 정보조회</span> */}
            </div>
            <ChartAndCardContainer
              activeServices={activeServices}
              activeTab="active"
              sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                isLegendSelected={isLegendSelected}
                // setIsLegendSelected={setIsLegendSelected}
                // handleMobileListClick={handleMobileListClick}
            />
            </>
            
          )}
          {/* <ChartAndCardContainer
            // recentServices={recentServices}
            activeServices={activeServices}
            activeTab="active"

          /> */}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item
        eventKey="2"
        className={`services-accordion-item ${
          activeKey === "2" ? "active" : ""
        }`}
      >
        <Accordion.Header className="services-accordion-header">
        <div className="d-flex flex-column flex-md-row align-items-centers ">
            <p className="me-3 mb-0">
              전송요구 철회 내역
            </p>
            <p className="services-accordion-date mb-0"> (조회 기간 : {getDateRange(365).startDate} ~ 
            {getDateRange(365).endDate})</p>
          </div>
        </Accordion.Header>
        <Accordion.Body className="services-accordion-body">
         <div className="card-slider-info-title mb-3">
              <span><i className="icon-login-info"></i> 오늘 기준 1년 (365일)이전 철회내역 조회</span>
            </div> 
            {(() => {
      // revokedServices 데이터에서 `trsm_rqust_stts_cd === "1"`인 경우 필터링
      const filteredRevokedServices = revokedServices?.filter((card) =>
        card.data_providers?.some(
          (provider) => provider.trsm_rqust_stts_cd === "1"
        )
      );

      if (!isDataAvailable(filteredRevokedServices)) {
        // 필터링된 데이터가 없을 경우
        return <NoDataMessage />;
      }

      // 필터링된 데이터가 있을 경우 RevokedCardSlider 렌더링
      return (
        <RevokedCardSlider
          title="전송 철회 내역"
          initialCardsData={filteredRevokedServices}
          isRevokedTab={true}
          sortOrder={sortOrder}
                setSortOrder={setSortOrder}
         
        />
      );
    })()}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Main;
