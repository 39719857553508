import React, { useEffect, useState } from "react";
import ChartAndCardDesktop from "./ChartAndCardDesktop";
import ChartAndCardMobile from "./ChartAndCardMobile";
import { convertDataToChartData } from "../../utils/utils";
import useWindowSize from "../../hooks/useWindowSize"; // useWindowSize 훅 가져오기
import CardSectionMobile from "../Card/CardSectionMobile";
import NoDataMessage from "../../error/NoDataMessage";
import { setLegendSelected } from "../../redux/legendSlice"; 
import { useSelector, useDispatch } from "react-redux";
export default function ChartAndCardContainer({
  recentServices,
  activeServices,
  activeTab,
  sortOrder,
  setSortOrder,
  isLegendSelected
  // isLegendSelected,
  // setIsLegendSelected,
  // handleMobileListClick
}) {
  const windowSize = useWindowSize(); // 화면 크기를 가져오는 useWindowSize 훅 사용
  const isMobileOrTablet = windowSize.width <= 1328; // 너비가 768px 이하인 경우 모바일로 간주
  const dispatch = useDispatch()   
  // const dispatch = useDispatch(); 
  // const isLegendSelected = useSelector((state) => {
  //   console.log("Redux 상태 구독 중: isLegendSelected =", state.legend.isLegendSelected);
  //   return state.legend.isLegendSelected;
  // });

  useEffect(() => {
    console.log("ChartAndCardContainer에서 Redux 상태 변경 감지: isLegendSelected =", isLegendSelected);
  }, [isLegendSelected]);
  const isRecent = activeTab === "recent";
  const cardsData = isRecent ? recentServices : activeServices;
  const chartData = convertDataToChartData(cardsData, false);
// console.log("chartData,chartDatachartDatachartDatachartDatachartData",cardsData);
  const [isViewAll, setIsViewAll] = useState(false);
  // const [isLegendSelected, setIsLegendSelected] = useState(false);
  // const [sortOrder, setSortOrder] = useState("recent");
  const [filteredCardsData, setFilteredCardsData] = useState(cardsData);
  const [selectedCategory, setSelectedCategory] = useState("전체");
  const [currentPage, setCurrentPage] = useState(1);
  const [activeIndex, setActiveIndex] = useState(
    Array.from({ length: chartData.length }, (_, i) => i)
  );
  // useEffect(() => {
  //   if (cardsData && chartData.length > 0) {
  //     handleViewAllClick();
  //   }
  // }, [chartData]);
  
  // useEffect(() => {
  //   setFilteredCardsData(cardsData);
  // }, [cardsData]);
  

  // useEffect(() => {
  //   if (selectedCategory && selectedCategory !== "전체") {
  //     // 특정 카테고리가 선택된 경우, 해당 카테고리로 필터링
  //     const filteredData = cardsData.filter(
  //       (card) => card.fld_nm === selectedCategory
  //     );
  //     setFilteredCardsData(filteredData);
  //   } else {
  //     // 카테고리가 선택되지 않았거나 "전체"가 선택된 경우, 모든 카드 표시
      
  //     setFilteredCardsData(cardsData);
  //     console.log("초기상태");
  //     // handleViewAllClick();  
  //     // setActiveIndex(Array.from({ length: chartData.length }, (_, i) => i));
    
  //   }
  // }, [cardsData, selectedCategory, chartData]);
  
  useEffect(() => {
    if (selectedCategory === "전체") {
      setFilteredCardsData(cardsData);
      setActiveIndex(Array.from({ length: chartData.length }, (_, i) => i));
      console.log("초기 상태: 전체 데이터 표시");
    }
  }, [cardsData]);
  
  const handlePieClick = (categoryName, index) => {
    // if (categoryName === "전체") {
    //   handleViewAllClick();
    //   return;
    // }
  // 이미 선택된 카테고리를 다시 클릭했을 때
    if (selectedCategory === categoryName) {
      setSelectedCategory("전체"); // 선택된 카테고리를 초기화
      setFilteredCardsData(cardsData); 
      setActiveIndex(Array.from({ length: chartData.length }, (_, i) => i));
      setCurrentPage(1);
    } else {
       // 새로운 카테고리를 선택했을 때
      setSelectedCategory(categoryName);
      // 선택한 카테고리명과 같은 카테고리명을 갖은 카드데이터로 초기화
      const filteredData = cardsData.filter(
        (card) => card.fld_nm === categoryName
      );
      setFilteredCardsData(filteredData);
      // console.log("filteredData", filteredData);
      setActiveIndex([index]);
      setCurrentPage(1);
    }
  };

  const handleViewAllClick = () => {
    setSelectedCategory("전체");
    setFilteredCardsData(cardsData);
    setActiveIndex(Array.from({ length: chartData.length }, (_, i) => i));
  };

  // 범례 클릭 시 CardSectionMobile을 표시하고, 선택된 카테고리로 필터링
  // const handleLegendClick = (categoryName, index) => {
  //   // handlePieClick(categoryName, index);
  //   const filteredData = cardsData.filter(
  //     (card) => card.fld_nm === categoryName
  //   );
  //   setFilteredCardsData(filteredData);
  //   setIsLegendSelected(true);
  //   console.log("handleLegendClick filteredCardsData", filteredCardsData);
  // };
  
    const handleLegendClick = (categoryName, index) => {
    if (categoryName === "전체") {
      // 전체가 선택된 경우, 모든 데이터를 필터링 없이 설정
      setFilteredCardsData(cardsData);
    } else {
      // 특정 카테고리가 선택된 경우, 해당 데이터만 필터링
      const filteredData = cardsData.filter((card) => card.fld_nm === categoryName);
      setFilteredCardsData(filteredData);
    }
    dispatch(setLegendSelected(true));
    // setIsLegendSelected(true);
    console.log("handleLegendClick filteredCardsData", cardsData);
  };
  


  // 뒤로가기 버튼 클릭 시 ChartAndCardMobile로 돌아가기
  const handleBackClick = () => {
    dispatch(setLegendSelected(false)); // Redux 상태 변경
  };


  // const handleMobileListClick = () => {
  //   // 모바일 목록 이동 시 실행될 로직
  //   console.log("모바일 목록 이동 실행!");
  //   setIsLegendSelected(true); // 모바일 목록 화면으로 돌아감
  // };
  // console.log("isLegendSelecte ChartAndCardContainerChartAndCardContainer",isLegendSelected );

  if (isMobileOrTablet) {
    // 모바일 환경에서의 컴포넌트 렌더링
    if (isLegendSelected) {
      // <p>isLegendSelected 상태: {isLegendSelected ? "true" : "false"}</p>
      return (
        <CardSectionMobile
          // onBackClick={handleBackClick}
          onBackClick={() => dispatch(setLegendSelected(false))}
          isViewAll={isViewAll}
          setIsViewAll={setIsViewAll}
          filteredCardsData={filteredCardsData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handleBackClick={handleBackClick}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          // handleMobileListClick={handleMobileListClick}
        />
      );
    }
    return (
      <ChartAndCardMobile
        chartData={chartData}
        totalCardCount={cardsData.length}
        filteredCardsData={filteredCardsData}
        handlePieClick={handlePieClick}
        handleLegendClick={handleLegendClick}
        handleViewAllClick={handleViewAllClick}
        activeIndex={activeIndex}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
      />
    );
  }
  // if (!cardsData || cardsData.length === 0) {
  //   return (
  //     <NoDataMessage
  //       type={isRecent ? "recentServices" : "activeServices"}
  //     />
  //   );
  // }
  // 데스크탑 환경에서는 기존 컴포넌트 렌더링
  return (
    
    <ChartAndCardDesktop
      chartData={chartData}
      filteredCardsData={filteredCardsData}
      handlePieClick={handlePieClick}
      // handleViewAllClick={handleViewAllClick}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      activeIndex={activeIndex}
      isViewAll={isViewAll}
      setIsViewAll={setIsViewAll}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
    />
  );
}
