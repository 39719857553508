// import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
// import { Navigation, Pagination, EffectCoverflow } from "swiper/modules";

// // import "../Card/CardPopupModal/CardCarouselStyle.css";
// import Card from "../Card/Card";
// import "./CardSwiperStyle.css";

// const CardSwiper = ({
//   cardsData,
//   isRevoked = false,
//   currentIndex,
//   setCurrentIndex,
//   swiperRef
// }) => {
//   return (
//     <section className="custom-provider-wrapper">

//       <div class="custom-swiper list-table-card">
//       <Swiper
//         speed={800}
//         spaceBetween={16}
//         slidesPerView={1}
//         loop={true}
//         navigation={true} 
//         modules={[Navigation]} 
//         className="custom-swiper-container"
//         onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex)}
//         ref={swiperRef} 
//       >
//         {cardsData.map((card, index) => (
//         <SwiperSlide key={card.uniqueKey}>

//      <Card key={card.uniqueKey} card={card} isRevoked={isRevoked} />

//           </SwiperSlide>
//         ))}
//       </Swiper>
//       <div class="slider-controls">
//         <div class="slider-pagination">1/4</div>
//             <div class="slider-navigation">
//               {/* <button type="button" class="btn btn-slider btn-play" title="슬라이더 멈춤/재생">
//                           <i class="icon-slider-play"></i>
//                       </button> */}
//                       <button type="button" class="btn btn-slider btn-prev">
//                           <i class="icon-prev"></i>
//                       </button>
//                       <button type="button" class="btn btn-slider btn-next">
//                           <i class="icon-next"></i>
//                       </button>
//                   </div>
//               </div>
//               </div>
//     </section>
//   );
// };

// export default CardSwiper;import React, { useEffect, useRef } from "react";
import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Card from "../Card/Card";
import "./CardSwiperStyle.css";

const CardSwiper = ({ cardsData, currentIndex, setCurrentIndex, swiperRef, isRevoked }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiperInstance = swiperRef.current.swiper;
      swiperInstance.params.navigation.prevEl = prevRef.current;
      swiperInstance.params.navigation.nextEl = nextRef.current;
      swiperInstance.navigation.init();
      swiperInstance.navigation.update();
    }
  }, [swiperRef]);

  return (
    <section className="custom-provider-wrapper swiper">
      <div className="custom-swiper list-table-card">
        <Swiper
          speed={500}
          spaceBetween={16}
          slidesPerView={1}
          loop={true}
          navigation={{
            nextEl: nextRef.current,
            prevEl: prevRef.current,
          }}
          modules={[Navigation]}
          onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex)}
          ref={swiperRef}
        >
          {cardsData.map((card) => (
            <SwiperSlide key={card.uniqueKey}>
              <Card card={card} />
            </SwiperSlide>
          ))}
        </Swiper>

        {/* slider-controls: mt-0은 리보크 상태가 아닌 경우에만 추가 */}
        {/* <div className={`slider-controls ${!isRevoked ? "mt-0" : ""}`}> */}
        <div className="slider-controls">
          <div className="slider-pagination">
            {`${currentIndex + 1} / ${cardsData.length}`}
          </div>
          <div className="slider-navigation">
            <button
              type="button"
              className="btn btn-slider btn-prev"
              ref={prevRef}
            >
              <i className="icon-prev"></i>
            </button>
            <button
              type="button"
              className="btn btn-slider btn-next"
              ref={nextRef}
            >
              <i className="icon-next"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardSwiper;
