import { createSlice } from "@reduxjs/toolkit";

const legendSlice = createSlice({
  name: "legend",
  initialState: {
    isLegendSelected: false,
  },
  reducers: {
    setLegendSelected: (state, action) => {
      console.log("setLegendSelected 호출, 이전 상태: ", state.isLegendSelected);
      console.log("setLegendSelected 호출, 변경값: ", action.payload);
      state.isLegendSelected = action.payload;
      console.log("setLegendSelected 호출, 이후 상태: ", state.isLegendSelected);
    },
  },
});


export const { setLegendSelected } = legendSlice.actions;

export default legendSlice.reducer;
