import React, { useEffect, useState } from "react";
import DataPieChart from "../Chart/DataPieChart";
import CardSlider from "../CardSlider/CardSlider";
import infoIcon from "../../assets/info.svg";
import CustomButton from "../common/button/CustomButton";
import "./ChartAndCardContainerStyle.css";
export default function ChartAndCardDesktop({
  chartData,
  filteredCardsData,
  handlePieClick,
  handleViewAllClick,
  activeIndex,
  isViewAll,
  setIsViewAll,
  sortOrder,
  setSortOrder,
}) {
  const subTitle =
    sortOrder === "recent"
      ? "전일 기준 1개월(30일)이전 정보조회"
      : "전일 기준 1년 (365일)이전 정보조회";

  // const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  // const isRecent = activeTab === "recent";
  // const cardsData = isRecent ? recentServices : activeServices;
  // const chartData = convertDataToChartData(cardsData, false);

  // const [isViewAll, setIsViewAll] = useState(false);
  // const [filteredCardsData, setFilteredCardsData] = useState(cardsData);
  // const [selectedCategory, setSelectedCategory] = useState(null);
  // const [activeIndex, setActiveIndex] = useState(
  //   Array.from({ length: chartData.length }, (_, i) => i)
  // );

  // useEffect(() => {
  //   setFilteredCardsData(cardsData);
  // }, [cardsData]);

  // const handlePieClick = (categoryName, index) => {
  //   if (categoryName === "전체") {
  //     handleViewAllClick();
  //     return;
  //   }

  //   if (selectedCategory === categoryName) {
  //     setFilteredCardsData(cardsData);
  //     setSelectedCategory(null);
  //     setActiveIndex(Array.from({ length: chartData.length }, (_, i) => i));
  //   } else {
  //     const filteredData = cardsData.filter(
  //       (service) => getServiceTag(service.service_code) === categoryName
  //     );
  //     setFilteredCardsData(filteredData);
  //     setSelectedCategory(categoryName);
  //     setActiveIndex([index]);
  //   }
  // };

  // const handleViewAllClick = () => {
  //   setFilteredCardsData(cardsData);
  //   setSelectedCategory("전체");
  //   setActiveIndex(Array.from({ length: chartData.length }, (_, i) => i));
  // };

  return (
    <section className="chart-card-main-container">
      <div className="chart-card-container">
        <div className="chart-card-section">
          <div className="chart-section">
            {/* <div className="chart-card-main-title"> */}
            <div className="card-slider-info-title">
              <img src={infoIcon} alt="info" />
              <p>{subTitle}</p>
            </div>
            {/* </div> */}
            <div className="chart-pie">
              <DataPieChart
                data={chartData}
                onPieClick={handlePieClick}
                activeIndex={activeIndex}
              />
            </div>
            {/* 전체 보기 버튼 */}
            <div className="view-all-button-container">
              <CustomButton
                className="view-all"
                variant="primary"
                label="전체보기"
                onClick={handleViewAllClick}
              >
                <p> 전체보기</p>
              </CustomButton>
              {/* <CustomButton
                variant="primary"
                size="md"
                className="filter-view-all"
                onClick={handleViewAllClick}
              >
                <p> 전체보기</p>
              </CustomButton> */}
            </div>
          </div>
          <div className="card-section">
            <CardSlider
              cardsData={filteredCardsData}
              isViewAll={isViewAll}
              toggleViewAll={() => setIsViewAll(!isViewAll)}
              sortOrder={sortOrder}
              // setSortOrder={handleSortOrderChange}
            />
          </div>
        </div>

        {/* showRevoked 상태에 따라 철회 내역 표시 */}
        {/* <div className="slider-line-container">
          <div className="slider-line"></div>
          {showRevoked ? (
            <p onClick={() => setShowRevoked(false)} className="toggle-link">
              철회 항목 닫기
            </p>
          ) : (
            <p onClick={() => setShowRevoked(true)} className="toggle-link">
              철회 항목 열기
            </p>
          )}
          <div className="slider-line"></div>
        </div> */}
        {/* 철회 내역 슬라이더 */}
        {/* {showRevoked && (
          <RevokedCardSlider
            title="전송 철회 내역"
            subTitle={
              activeTab === "recent"
                ? "전일 기준 1개월(30일)이전 철회내역 정보조회"
                : "전일 기준 1년 (365일)이전 철회내역 정보조회"
            }
            cardsData={revokedServices}
          />
        )} */}
      </div>
    </section>
  );
}
