import React from "react";
import { useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();
  const state = location.state || {};

  const renderBreadcrumbs = () => {
    switch (location.pathname) {
      case "/ThirdPartyDetail":
        return (
          <>
            <li className="breadcrumb-item">
              <a href="/">
                <i className="icon-home"></i> 마이데이터 모아보기
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="">제3자 제공 동의 내역</a>
            </li>
          </>
        );
        
      case "/Detail":
        return (
          <>
            <li className="breadcrumb-item">
              <a href="/">
                <i className="icon-home"></i> 마이데이터 모아보기
              </a>
            </li>
            <li className="breadcrumb-item">
            상세보기
            </li>
          </>
        );
      default:
        return (
          <li className="breadcrumb-item">
            <a href="/">
              <i className="icon-home"></i> 마이데이터 모아보기
            </a>
          </li>
        );
    }
  };

  const renderTitle = () => {
    switch (location.pathname) {
      case "/ThirdPartyDetail":
        return "제3자 제공 동의 내역";
      case "/Detail":
        return `${state.card?.title} 상세보기` || "상세보기"; 
      default:
        return "마이데이터 모아보기";
    }
  };

  return (
    <nav aria-label="breadcrumb" className="location">
      <ol className="breadcrumb">{renderBreadcrumbs()}</ol>
      {/* 본문 타이틀 */}
      <h2 className="page-title">{renderTitle()} </h2>
    </nav>
  );
}

export default Navbar;
