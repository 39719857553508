// utils.js

// 서비스 코드에 따른 태그를 반환하는 함수
// // 서비스 코드에 따른 태그를 반환하는 함수
// export const getServiceTag = (fld_cd) => {
//   if (!fld_cd) return "기타";

//   switch (fld_cd) {
//     case "O1":
//       return "공공";
//     case "K1":
//       return "금융";
//     case "R1":
//       return "문화";
//     case "Q1":
//       return "의료";
//     case "Q2":
//       return "복지";
//     case "J1":
//       return "통신";
//     case "C1":
//       return "에너지";
//     case "Z1":
//       return "해당없음";
//     default:
//       return "기타";
//   }
// };
export const formatDate = (dateString) => {
  // 입력값 유효성 검사
  if (!dateString) return "날짜 정보 없음";

  // YYYYMMDD 형식인지 확인
  if (dateString.length === 8 && /^\d{8}$/.test(dateString)) {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return `${year}.${month}.${day}`;
  }

  // YYYY-MM-DD HH:mm:ss 형식인지 확인
  if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(dateString)) {
    const [datePart] = dateString.split(" "); // 날짜 부분만 추출
    const [year, month, day] = datePart.split("-");
    return `${year}.${month}.${day}`;
  }


};

const getServiceColor = (categoryName) => {
  switch (categoryName) {
    case "공공":
      return "#6CB6FF"; // 부드러운 하늘색
    case "금융":
      return "#4D91FF"; // 세련된 파란색
    case "문화":
      return "#F9A8D4"; // 은은한 핑크
    case "의료":
      return "#8CD7A2"; // 차분한 녹색
    case "복지":
      return "#FFE083"; // 따뜻한 노란색
    case "통신":
      return "#FFD966"; // 밝은 주황색
    case "에너지":
      return "#FF8C94"; // 부드러운 코랄색
    case "고용":
      return "#FFB347"; // 활기 있는 주황색
    case "노동":
      return "#A093FF"; // 중립적인 보라색
    case "교육":
      return "#A4D8F4"; // 신뢰감을 주는 연한 하늘색
    case "국토":
      return "#FFDDC1"; // 따뜻한 살구색
    case "교통":
      return "#FFD700"; // 눈에 띄는 황금색
    case "부동산":
      return "#93C47D"; // 차분한 초록색
    case "유통":
      return "#FF9999"; // 부드러운 분홍색
    case "해당없음":
      return "#CCCCCC"; // 중립적인 회색
    default:
      return "#999999"; // 기타
  }
};

// 서비스 데이터를 차트에서 사용할 수 있는 데이터 형식으로 변환하는 함수
export const convertDataToChartData = (services) => {

  // console.log(">>>>>>>>>>>>> services", services)
  const chartData = services.reduce((acc, service) => {
   const categoryName = service.fld_nm;
  //  console.log(">>>>>>>>>>>>> categoryName", categoryName, acc)
    const existingCategory = acc.find((item) => item.name === categoryName);
    if (existingCategory) {
      existingCategory.value += 1; // 이미 있는 카테고리면 수량을 증가
    } else {
      const color = getServiceColor(categoryName);
      acc.push({
        name: categoryName,
        value: 1,
        color: color, // 카테고리 색상 사용
      });
    }
    return acc;
  }, []);

  return chartData;
};
