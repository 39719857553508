import React, { useEffect, useState } from "react";

import useWindowSize from "../../hooks/useWindowSize";
// import "./CardSectionMobileStyle.css";
//import CustomButton from "../common/button/CustomButton";
import CardSlider from "../CardSlider/CardSlider";

export default function CardSectionMobile({
  onBackClick,
  isViewAll,
  sortOrder,
  setSortOrder,
  setIsViewAll,
  filteredCardsData,
  currentPage,
  setCurrentPage,
  handleBackClick
  
}) {
  return (
    <div className="card-section-container-m">
      <div className="card-section-container-m">
        <section className="card-swipe-container-m">
          {/* <div className="card-section-m"> */}
          <CardSlider
            onBackClick={onBackClick}
            initialCardsData={filteredCardsData}
            isViewAll={isViewAll}
            currentPage={currentPage}
            // toggleViewAll={() => setIsViewAll(!isViewAll)}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            setCurrentPage={setCurrentPage}
            // setSortOrder={handleSortOrderChange}
            handleBackClick={handleBackClick}
            
            // handleMobileListClick={handleMobileListClick}
          />
          {/* </div> */}
        </section>
      </div>
    </div>
  );
}
