import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getActiveServices,
  getrevokedServices,
  getRecentServices,
  getServiceThirdPartyDetails,
} from "../service/externalDataServices";
export const fetchActiveServices = createAsyncThunk(
    "services/getActiveServices",
    async (id) => {
      const response = await getActiveServices(id);
      // 서비스 객체는 그대로 두고, data_providers만 필터링
      return response.map((service) => ({
        ...service,
        data_providers: service.data_providers.filter(
          (provider) =>
            provider.trsm_rqust_stts_cd === "0" || provider.trsm_rqust_stts_cd === "3"
        ),
      }));
    }
  );
  
  export const fetchRecentServices = createAsyncThunk(
    "services/fetchRecentServices",
    async (id) => {
      const response = await getRecentServices(id);
      // 서비스 객체는 그대로 두고, data_providers만 필터링
      return response.map((service) => ({
        ...service,
        data_providers: service.data_providers.filter(
          (provider) =>
            provider.trsm_rqust_stts_cd === "0" || provider.trsm_rqust_stts_cd === "3"
        ),
      }));
    }
  );
  
  export const fetchRevokedServices = createAsyncThunk(
    "services/fetchRevokedServices",
    async (id) => {
      const response = await getrevokedServices(id);
      return response; // 서비스 객체는 그대로 반환
    }
  );

  export const fetchThirdPartyDetails = createAsyncThunk(
    "services/fetchThirdPartyDetails",
    async ({ rsognCd, trsmRqustfId }) => {
      const response = await getServiceThirdPartyDetails(rsognCd, trsmRqustfId);
      return response; // 제3자 제공 데이터 반환
    }
  );

const servicesSlice = createSlice({
    name: "services",
    initialState: {
      activeServices: [],
      revokedServices: [],
      recentServices: [],
      thirdPartyDetails: [],
      status: "idle",
      error: null,
    },
    reducers: {
      // 특정 서비스 상태 업데이트
      updateServiceState: (state, action) => {
        const { serviceId, providerId, newStatus } = action.payload;
  
        // activeServices와 recentServices에서 해당 서비스를 찾아서 상태 업데이트
        const updateProvidersStatus = (services) => {
          return services.map((service) => {
            if (service.id === serviceId) {
              return {
                ...service,
                data_providers: service.data_providers.map((provider) =>
                  provider.trsm_rqustf_id === providerId
                    ? { ...provider, trsm_rqust_stts_cd: newStatus }
                    : provider
                ),
              };
            }
            return service;
          });
        };
  
        state.activeServices = updateProvidersStatus(state.activeServices);
        state.recentServices = updateProvidersStatus(state.recentServices);
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchActiveServices.fulfilled, (state, action) => {
          state.activeServices = action.payload;
        })
        .addCase(fetchRevokedServices.fulfilled, (state, action) => {
          state.revokedServices = action.payload;
        })
        .addCase(fetchRecentServices.fulfilled, (state, action) => {
          state.recentServices = action.payload;
        })
        .addCase(fetchThirdPartyDetails.fulfilled, (state, action) => {
          state.thirdPartyDetails = action.payload;
        });
    },
  });
  
  export const { updateServiceState } = servicesSlice.actions;
  export const selectThirdPartyDetails = (state) => state.services.thirdPartyDetails;
  export default servicesSlice.reducer;
  
