import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user'
import servicesReducer from "./servicesSlice";
import pageReducer from "./pageSlice"; 
import chartAndCardReducer from "./chartAndCardSlice";
import legendReducer from './legendSlice';
const store = configureStore({
    reducer: {
      user: userReducer,
      services: servicesReducer,
      chartAndCard: chartAndCardReducer,
      page: pageReducer, 
      legend: legendReducer,
    },
  });
  
  export default store;
  
  