import { createSlice } from "@reduxjs/toolkit";

const chartAndCardSlice = createSlice({
  name: "chartAndCard",
  initialState: {
    selectedCategory: "전체",
    filteredCardsData: [],
    currentPage: 1,
    itemsPerPage: 2,
    cardsData: [], // 전체 서비스 데이터
  },
  reducers: {
    setCardsData: (state, action) => {
      state.cardsData = action.payload;
      state.filteredCardsData = action.payload; // 초기에는 전체 데이터를 필터링
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
      state.currentPage = 1; // 카테고리 변경 시 페이지 초기화

      // 카테고리 필터링 로직
      state.filteredCardsData =
        action.payload === "전체"
          ? state.cardsData
          : state.cardsData.filter((card) => card.fld_nm === action.payload);
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    resetFilteredCards: (state) => {
      state.filteredCardsData = state.cardsData;
      state.selectedCategory = "전체";
      state.currentPage = 1;
    },
  },
});

export const {
  setCardsData,
  setSelectedCategory,
  setCurrentPage,
  setItemsPerPage,
  resetFilteredCards,
} = chartAndCardSlice.actions;

export default chartAndCardSlice.reducer;
