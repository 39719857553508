// import React from 'react';

// function Header() {
//   return (
//     <header id="header" className="header">
//           {/* 정부 공지 영역 */}
//           <div className="header-container header-official-gov-bn">
//         <div className="container d-flex justify-content-between align-items-center px-0 ">
//           <p className=" gap-3 ">
//             <i className="icon-korea-flag"></i> 이 누리집은 대한민국 공식 전자정부 누리집입니다.
            
//           </p>
//           {/* <div className="d-none d-md-flex header-display-size">
//             <button type="button" className="btn btn-outline-light btn-display-size">
//               <i className="icon-minus-xs"></i>
//             </button>
//             <span className="display-size-desc">
//               화면크기 <span>100</span>%
//             </span>
//             <button type="button" className="btn btn-outline-light btn-display-size">
//               <i className="icon-plus-xs"></i>
//             </button>
//           </div>            */}
//         </div>
//       </div>

//       {/* 로고 및 메인 메뉴 영역 */}
//       <div className="header-container header-contents">
//         <div className="container px-0">

//           <nav className="navbar header-logo-btns">
//             <a className="navbar-brand focus-ring" href="/main">
//               <i className="icon-header-logo"></i>
//               <span className="visually-hidden">범정부 마이데이터</span>
//               {/* <span>|</span> */}
//               <span className="d-none d-md-block sub-name">모아보기</span>
//             </a>
//           </nav>
//         </div>
//       </div>

//       {/* GNB 영역 */}
//       {/* <div className="d-none d-md-block header-container header-nav">
//         <div className="navbar-expand-md">
//           <div className="container px-0">
//             <ul className="navbar-nav"></ul>
//             <button
//               id="btnMenuToggle"
//               className="btn fw-bold btn-menu-toggle"
//               type="button"
//               data-bs-target=".header-lnb-wrap"
//               aria-label="전체 메뉴 열기"
//             >
//               <i className="icon-gnb-toggle"></i>
//             </button>
//           </div>
//         </div>
//       </div> */}

//       {/* LNB 영역 */}
//       {/* <div className="header-lnb-wrap">
//         <div
//           className="offcanvas offcanvas-end header-lnb"
//           data-bs-backdrop="static"
//           data-bs-keyboard="false"
//           tabIndex="-1"
//           id="headerLnb"
//           aria-labelledby="staticBackdropLabel"
//         >
//           <div className="offcanvas-header">
//             <div className="lnb-header-bar">
//               <div className="offcanvas-title" id="staticBackdropLabel">
//                 <i className="d-md-none icon-gnb-toggle"></i> 전체메뉴
//               </div>
//               <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
//             </div>
//           </div>
//           <div className="offcanvas-body">
//             <div className="lnb-body-menu">
//               <ul className="list-unstyled mb-0 lnb-menu-wrap" role="tablist" id="allMenuList"></ul>
//             </div>
//           </div>
//           <div className="d-none d-md-block offcanvas-footer">
//             <div className="board-btn-group">
//               <div className="col-12 col-md-auto ms-md-auto">
//                 <button type="button" className="btn btn-tertiary" data-bs-dismiss="offcanvas" aria-label="Close">
//                   닫기
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div> */}
//     </header>
//   );
// }

// export default Header;


import React, { useEffect } from "react";
function zoomControl(action) {
  const zoomPerElement = document.getElementById('zoomPer');
  let currentZoom = parseInt(zoomPerElement.innerText, 10); // 현재 확대 비율

  if (action === 'plus' && currentZoom < 100) {
      currentZoom += 10; // 확대
  } else if (action === 'minus' && currentZoom > 70) {
      currentZoom -= 10; // 축소
  }

  zoomPerElement.innerText = currentZoom; // 비율 업데이트
  document.body.style.zoom = currentZoom / 100; // 화면 확대/축소 적용
}


  function Header() {


  return (
    <>
      {/* 공식 전자정부 배너 */}
      <div className="header-container header-official-gov-bn">
        <div className="container px-0 d-flex align-items-center justify-content-between ">
          <p className="d-flex align-items-center gap-3 mb-0">
            <i className="icon-korea-flag"></i>
            이 누리집은 대한민국 공식 전자정부 누리집입니다.
          </p>

          <div className="d-none d-md-flex header-display-size">
            <button
              type="button"
              id="btn_minus"
              onClick={() => zoomControl("minus")}
              className="btn btn-outline-light btn-display-size"
            >
              <i className="icon-minus-xs"></i>
            </button>
            <span className="display-size-desc">
              화면크기 <span id="zoomPer">100</span>%
            </span>
            <button
              type="button"
              id="btn_plus"
              onClick={() => zoomControl("plus")}
              className="btn btn-outline-light btn-display-size"
            >
              <i className="icon-plus-xs"></i>
            </button>
          </div>

        </div>
      </div>

      {/* 헤더 컨텐츠 */}
      <div className="header-container header-contents">
        <div className="container px-0">
           {/* 화면 크기 조절 */}
          <div className="header-display-size ">
            {/* <button
              type="button"
              id="btn_minus"
              // onClick={() => zoomControl("minus")}
              className="btn btn-outline-light btn-display-size"
            >
              <i className="icon-minus-xs"></i>
            </button>
            <span className="display-size-desc">
              화면크기 <span id="zoomPer">100</span>%
            </span>
            <button
              type="button"
              id="btn_plus"
              // onClick={() => zoomControl("plus")}
              className="btn btn-outline-light btn-display-size"
            >
              <i className="icon-plus-xs"></i>
            </button> */}
          </div>

          {/* 로고 */}
          <nav className="navbar header-logo-btns ">
            <a className="navbar-brand focus-ring" href="/main" id="mainLogo">
              <i className="icon-header-logo"></i>
              <span className="visually-hidden">범정부 마이데이터</span>
           
                <span className="d-none d-md-block sub-name">모아보기</span>
          
            </a>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
