import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setPage } from "../../redux/pageSlice"; // 페이지 상태 액션 불러오기
import { useNavigate } from "react-router-dom";
import "../../index.css";

// import TurnIcon from "../../assets/turn.svg";
// import CardFront from "./CardFront";
// import CardBack from "./CardBack";
import "./CardStyle.css";
//import CustomButton from "../common/button/CustomButton";

// import CardDetailModal from "../CardPopupModal/CardDetailModal";
import { formatDate } from "../../utils/utils";
// import { getServiceThirdPartyDetails } from "../../service/externalDataServices";
import CardRevokeFront from "./CardRevokeFront";
import { fetchThirdPartyDetails } from "../../redux/servicesSlice";
import queryString from 'query-string';
export default function Card({ card, isRevoked }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [isFlipped, setIsFlipped] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [thirdPartyData, setThirdPartyData] = useState(null);
  const [consentStatus, setConsentStatus] = useState(null); // 동의 상태 초기값 설정
  const [modalDataProviders, setModalDataProviders] = useState([]);

  const handleUpdateDataProviders = (updatedProviders) => {
    setModalDataProviders(updatedProviders);
  };
  // const handleShowDetail = (page) => {
  //   dispatch(setPage(page)); // 페이지 변경
  // };
  
  const hasThirdPartySharing =

  card.share_requests?.some(
    (request) => request.third_party_sharing_allowed === "Y"
  ) || false;
  
const handleShowDetail = async () => {
  const { id } = queryString.parse(window.location.search); // 현재 쿼리스트링에서 id 가져오기
  const { rsogn_cd: rsognCd, share_requests } = card;

  if (!share_requests || share_requests.length === 0) {
    console.error("share_requests 배열이 비어 있습니다.");
    return;
  }

  // 첫 번째 share_request의 trsm_rqustf_id를 가져옵니다.
  const { trsm_rqustf_id: cardTrsmRqustfId } = share_requests[0];

  try {
    // fetchThirdPartyDetails로 데이터를 가져옵니다.
    const thirdPartyDetails = await dispatch(
      fetchThirdPartyDetails({ rsognCd, trsmRqustfId: cardTrsmRqustfId })
    ).unwrap();

    console.log("thirdPartyDetails:", thirdPartyDetails);

    // thirdPartyDetails에서 카드의 trsmRqustfId와 일치하는 데이터만 필터링합니다.
    const filteredDetails = thirdPartyDetails.filter(
      (detail) => detail.trsmRqustfId === cardTrsmRqustfId
    );

    console.log("filteredDetails:", filteredDetails);

    if (filteredDetails.length === 0) {
      console.error("일치하는 제3자 제공 데이터가 없습니다.");
      return;
    }

    // 필터링된 데이터를 navigate로 전달합니다.
    navigate(`/ThirdPartyDetail?id=${id}`, { state: { thirdPartyDetails: filteredDetails } });
  } catch (error) {
    console.error("제3자 제공 데이터 조회 중 오류 발생:", error);
  }
};

const handleNavigateToDetail = () => {
  const { id } = queryString.parse(window.location.search);
  navigate(`/Detail?id=${id}`, { state: { card } });
};

  
  
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

// 철회된 카드일 경우
  // 철회된 카드의 기본 정보를 표시하는 앞면 컴포넌트
 // 철회된 카드만 렌더링
  if (
    card.data_providers?.some((provider) => provider.trsm_rqust_stts_cd === "1")
  ) {
    return <CardRevokeFront card={card} />
    ;
  }
  
  // 일반 카드일 경우
  // const {
  //   title,
  //   serviceProvider,
  //   data_providers: dataProviders,
  //   service_code: serviceCode,
  //   fld_nm: fld_nm,
  //   last_consent_date: lastConsentDate,
  // } = card;

  // 필터링된 데이터 프로바이더
  const filteredDataProviders = card.data_providers?.filter(
    (provider) =>
      provider.trsm_rqust_stts_cd === "0" || provider.trsm_rqust_stts_cd === "3"
  ) || [];

  const dataProvidersText =
    filteredDataProviders.length > 0
      ? filteredDataProviders.length > 1
        ? `${filteredDataProviders[0].data_provider} 외 ${
            filteredDataProviders.length - 1
          }건`
        : filteredDataProviders[0].data_provider
      : "제공 기관 없음";

  return (
    <div className="panel card-item p-8 " >
      {/* 카드 타이틀 */}
      <div className="card-item-content">
        <div className="card-item-body mt-0">
          <h4
            // href="javascript:;"
            // className="stretched-link service-name"
            // onClick={handleShowModal}
          >
            {card.title}
          </h4>
          <div className="inst-name">{formatDate(card.last_consent_date)}</div>
          <div className="service-desc"> <span className="card-item-label">[정보수신자] : </span>{card.serviceProvider}</div>
          <div className="service-desc"> <span className="card-item-label">[정보전송자] : </span> {dataProvidersText}</div>
        </div>
      </div>

      {/* 태그 및 버튼 */}
      <div className="card-item-tag-btn">
        <div className="tags ">
          {card.fld_nm && (
            <span className="btn btn-light btn-service-tag category custom-btn ">
              #{card.fld_nm}
            </span>
          )}
          {card.fld_nm !== "금융" && card.fld_nm !== "공공" && (
            <>
              <span className="btn btn-light btn-service-tag custom-btn ">#철회 가능</span>
              {hasThirdPartySharing && (
                <span className="btn btn-light btn-service-tag custom-btn">
                  #제3자 제공 중
                </span>
              )}
            </>
          )}
        </div>
        <div className="mt-6">
  <div className="d-flex gap-2 justify-content-end">
    {hasThirdPartySharing && (
      <button
        type="button"
        id="popBtn"
        onClick={handleShowDetail}
        className="btn btn-primary w-50 custom-btn "
      
      >
        제3자 제공현황
      </button>
    )}

    <button
      type="button"
      id="popBtn"
      onClick={handleNavigateToDetail}
      className="btn btn-secondary w-50  custom-btn "
    >
      상세보기
    </button>
  </div>
</div>

      </div>
    </div>
  );
}
