import React, { useEffect, useState } from "react";
import ChartAndCardDesktop from "./ChartAndCardDesktop";
import ChartAndCardMobile from "./ChartAndCardMobile";
import { convertDataToChartData, getServiceTag } from "../../utils/utils";
import useWindowSize from "../../hooks/useWindowSize"; // useWindowSize 훅 가져오기
import CardSectionMobile from "../Card/CardSectionMobile";

export default function ChartAndCardContainer({
  recentServices,
  activeServices,
  activeTab,
}) {
  const windowSize = useWindowSize(); // 화면 크기를 가져오는 useWindowSize 훅 사용
  const isMobileOrTablet = windowSize.width <= 1100; // 너비가 768px 이하인 경우 모바일로 간주
  const isRecent = activeTab === "recent";
  const cardsData = isRecent ? recentServices : activeServices;
  const chartData = convertDataToChartData(cardsData, false);

  const [isViewAll, setIsViewAll] = useState(false);
  const [isLegendSelected, setIsLegendSelected] = useState(false);
  const [sortOrder, setSortOrder] = useState("recent");
  const [filteredCardsData, setFilteredCardsData] = useState(cardsData);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activeIndex, setActiveIndex] = useState(
    Array.from({ length: chartData.length }, (_, i) => i)
  );

  useEffect(() => {
    setFilteredCardsData(cardsData);
  }, [cardsData]);

  const handlePieClick = (categoryName, index) => {
    if (categoryName === "전체") {
      handleViewAllClick();
      return;
    }

    if (selectedCategory === categoryName) {
      setFilteredCardsData(cardsData);
      setSelectedCategory(null);
      setActiveIndex(Array.from({ length: chartData.length }, (_, i) => i));
    } else {
      const filteredData = cardsData.filter(
        (service) => getServiceTag(service.service_code) === categoryName
      );
      setFilteredCardsData(filteredData);
      setSelectedCategory(categoryName);
      setActiveIndex([index]);
    }
  };

  const handleViewAllClick = () => {
    setFilteredCardsData(cardsData);
    setSelectedCategory("전체");
    setActiveIndex(Array.from({ length: chartData.length }, (_, i) => i));
  };

  // 범례 클릭 시 CardSectionMobile을 표시
  const handleLegendClick = () => {
    setIsLegendSelected(true);
  };

  // 뒤로가기 버튼 클릭 시 ChartAndCardMobile로 돌아가기
  const handleBackClick = () => {
    setIsLegendSelected(false);
  };

  console.log("filteredCardsDatafilteredCardsData", filteredCardsData);
  if (isMobileOrTablet) {
    // 모바일 환경에서의 컴포넌트 렌더링
    if (isLegendSelected) {
      return (
        <CardSectionMobile
          onBackClick={handleBackClick}
          isViewAll={isViewAll}
          setIsViewAll={setIsViewAll}
          filteredCardsData={filteredCardsData}
        />
      );
    }
    return (
      <ChartAndCardMobile
        chartData={chartData}
        filteredCardsData={filteredCardsData}
        handlePieClick={handlePieClick}
        handleLegendClick={handleLegendClick} // 범례 클릭 핸들러 추가
        handleViewAllClick={handleViewAllClick}
        activeIndex={activeIndex}
        isViewAll={isViewAll}
        setIsViewAll={setIsViewAll}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
      />
    );
  }

  // 데스크탑 환경에서는 기존 컴포넌트 렌더링
  return (
    <ChartAndCardDesktop
      chartData={chartData}
      filteredCardsData={filteredCardsData}
      handlePieClick={handlePieClick}
      handleViewAllClick={handleViewAllClick}
      activeIndex={activeIndex}
      isViewAll={isViewAll}
      setIsViewAll={setIsViewAll}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
    />
  );
}
