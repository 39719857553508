// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 기본 모바일 스타일 */
.card-section-container-m {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  /* padding: 10px 20px; */
}

.card-swipe-container-m {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  align-items: center;
}

.card-section-m {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
/* 태블릿 환경일 때의 스타일 */
@media (min-width: 768px) and (max-width: 1110px) {
  .card-section-container-m {
    flex-direction: row; /* 태블릿일 때는 가로 정렬 */
    /* padding: 10px 20px; */
    /* height: auto; */
    /* width: 100%; */
  }

  .card-swipe-container-m {
    flex-direction: row; /* 태블릿일 때는 가로 정렬 */
    /* width: 100%; */
    /* 필요에 따라 다른 스타일 추가 */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Card/CardSectionMobileStyle.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,YAAY;AACd;AACA,mBAAmB;AACnB;EACE;IACE,mBAAmB,EAAE,kBAAkB;IACvC,wBAAwB;IACxB,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,mBAAmB,EAAE,kBAAkB;IACvC,iBAAiB;IACjB,qBAAqB;EACvB;AACF","sourcesContent":["/* 기본 모바일 스타일 */\n.card-section-container-m {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  flex-grow: 1;\n  /* padding: 10px 20px; */\n}\n\n.card-swipe-container-m {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  width: 100%;\n  align-items: center;\n}\n\n.card-section-m {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 20px 0px;\n  width: 100%;\n  height: 100%;\n  flex-grow: 1;\n}\n/* 태블릿 환경일 때의 스타일 */\n@media (min-width: 768px) and (max-width: 1110px) {\n  .card-section-container-m {\n    flex-direction: row; /* 태블릿일 때는 가로 정렬 */\n    /* padding: 10px 20px; */\n    /* height: auto; */\n    /* width: 100%; */\n  }\n\n  .card-swipe-container-m {\n    flex-direction: row; /* 태블릿일 때는 가로 정렬 */\n    /* width: 100%; */\n    /* 필요에 따라 다른 스타일 추가 */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
