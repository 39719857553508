// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-table-card .card-item .event-label-revoke {
  /* background-color: #EB246B; */
  background-color: #E4032E;
}

.panel-revoke {
  border: var(--bs-panel-border-width) solid #E4032E;
}    
@media (max-width: 1328px) {
  /*  모바일 (md 크기) */
  /* .card-md-btn {
    padding: 0.8rem 0.5rem;
 
  }

  .md-btn-font{ 
    font-size: 0.8rem !important; 
  } */
}`, "",{"version":3,"sources":["webpack://./src/components/Card/CardStyle.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,yBAAyB;AAC3B;;AAEA;EACE,kDAAkD;AACpD;AACA;EACE,iBAAiB;EACjB;;;;;;;KAOG;AACL","sourcesContent":[".list-table-card .card-item .event-label-revoke {\n  /* background-color: #EB246B; */\n  background-color: #E4032E;\n}\n\n.panel-revoke {\n  border: var(--bs-panel-border-width) solid #E4032E;\n}    \n@media (max-width: 1328px) {\n  /*  모바일 (md 크기) */\n  /* .card-md-btn {\n    padding: 0.8rem 0.5rem;\n \n  }\n\n  .md-btn-font{ \n    font-size: 0.8rem !important; \n  } */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
