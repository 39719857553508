
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// import queryString from "query-string";
import Accordion from "../components/Accordion/Accordion";
// import { setLegendSelected } from './redux/legendSlice'; 
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import useWindowSize from "../hooks/useWindowSize";
import { setLegendSelected } from '../redux/legendSlice';
export default function ThirdPartyDetailPage() {
  const location = useLocation();
  const navigate = useNavigate(); 
  const isLegendSelected = useSelector((state) => state.legend.isLegendSelected); // Redux 상태 가져오기
  const dispatch = useDispatch()   
  const windowSize = useWindowSize(); 
  const isMobileOrTablet = windowSize.width <= 1328;
  const { thirdPartyDetails } = location.state || [];
  // const handleGoToMain = () => {
  //   if (isMobileOrTablet) {
  //     console.log("handleMobileListClick 실행 전 Redux 상태: ", isLegendSelected); // Redux 상태 확인
  //     handleMobileListClick(); // Redux 상태 변경
  //     console.log("handleMobileListClick 실행 후 Redux 상태: ", isLegendSelected); // Redux 상태 확인
  //   } else {
  //     navigate("/"); // 메인 페이지로 이동
  //   }
  // };
  

  // 모바일에서 실행되는 함수
  const handleMobileGoToMain = () => {
    console.log("handleMobileListClick 실행 전 Redux 상태: ", isLegendSelected);
    dispatch(setLegendSelected(true)); // Redux 상태 변경
    setTimeout(() => {
        navigate("/"); // 상태 변경 후 페이지 이동
        console.log("handleMobileListClick 실행 후 Redux 상태: ", isLegendSelected);
    }, 100); // 약간의 지연 후 navigate 실행
};

  // 데스크탑에서 실행되는 함수
  const handleDesktopGoToMain = () => {
    navigate("/"); // 메인 페이지로 이동
  };

  // 목록 버튼 클릭 핸들러
  const handleGoToMain = () => {
    if (isMobileOrTablet) {
      handleMobileGoToMain();
    } else {
      handleDesktopGoToMain();
    }
  };

  // const { id } = queryString.parse(location.search); // URL의 쿼리스트링에서 ID 가져오기


  if (!thirdPartyDetails || thirdPartyDetails.length === 0) {
    return <div>제3자 제공 데이터가 없습니다.</div>;
  }

return (
    <div>
      {/* <h2>제3자 제공 동의 내역</h2>
   */}
      <Accordion thirdPartyDetails={thirdPartyDetails} />
      <div className="board-btn-group">
        <div className="col col-md-auto me-md-auto">
        <button
            type="button"
            className="btn btn-outline-dark btn-lg"
            onClick={handleGoToMain}
          >
            목록
          </button>
        </div>
        {/* <div className="col col-md-auto" id="trsmRetract_bt"> */}
          {/* <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={handleOpenConfirmModal}
          >
            철회하기
          </button> */}
        {/* </div> */}
      </div>
    </div>
    
  );
}
