import React from "react";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import "../../index.css";
export default function FilterToolbar({
  sortOrder,
  setSortOrder,
  cardsData = [],
  handleViewAll,
  isViewAll,
  isMobile,
  onBackClick,
  setCurrentPage,
  handleBackClick
}) {
  const navigate = useNavigate(); 
  
  const windowSize = useWindowSize(); // 창 크기 가져오기
  const isMobileView = windowSize.width <= 1328; // 모바일 뷰 판단

  const handleSortOrderChange = (order) => {
    console.log("handleSortOrderChange",order);
    setSortOrder(order);
    setCurrentPage(1);
  };
  const handleGoToMain = () => {
    navigate("/"); // MainPage로 이동
  };
  

  return (
    <div className="list-table-header">
      <div className="list-table-top">
        <div className="list-search-total fs-5 fw-bold">
          <span>총 </span>
          <span className="text-primary" id="totalCnt">
          {cardsData.length}
          </span>
          <span>개</span>
        </div>
        <div
          className={`d-flex ${
            isMobileView ? "justify-content-between" : "justify-content-end"
          } align-items-center table-list-control`}
        >
          {/* 모바일일 경우만 목록 버튼 추가 */}
          {isMobileView && (
        
              <div className="col col-md-auto me-md-auto">
                <button
                  type="button"
                  className="btn btn-outline-dark btn-sm cutom-btn"
                  onClick={handleBackClick}
                  // onClick={handleGoToMain}
                >
                  목록
                </button>
              </div>
            // </div>
          )}

          <div className="d-flex align-items-center gap-4 list-view-align">
        
            <span className="text-nowrap fw-bold">정렬기준</span>
            {/* Mobile Sort Select */}
{/* 
            <select
          className={`d-flex ${
            isMobileView ? "" : "d-md-none "
          } d-md-none form-select border-0`}
        > */}
              <select
          className={`d-flex ${
            isMobileView ? "" : "d-md-none "
          } d-md-none form-select border-0`}
              title="정렬기준 선택"
              onChange={(e) => handleSortOrderChange(e.target.value)}
            >
              <option value="recent" selected={sortOrder === "recent"}>
                최신순
              </option>
              <option value="old" selected={sortOrder === "old"}>
                오래된 순
              </option>
            </select>
            {/* Desktop Sort Options */}
            <div className="d-none d-md-flex">
              <input
                type="radio"
                className="btn-check"
                id="selectListAlign01"
                value="recent"
                checked={sortOrder === "recent"}
                onChange={() => handleSortOrderChange("recent")}
                autoComplete="off"
              />
              <label
                className="btn btn-underline border-0"
                htmlFor="selectListAlign01"
              >
                최신순
              </label>
              <input
                type="radio"
                className="btn-check"
                id="selectListAlign02"
                value="old"
                checked={sortOrder === "old"}
                onChange={() => handleSortOrderChange("old")}
                autoComplete="off"
              />
              <label
                className="btn btn-underline border-0"
                htmlFor="selectListAlign02"
              >
                오래된 순
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
