// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h2 .page-title {

}
@media (max-width: 1328px) {
  /*  모바일 (md 크기) */
  /* .services-accordion-title {
    font-size: 1rem;
  } */
  h2.page-title {
    font-size : 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .card-detail-title h2 {
    font-size : 1.3rem;
  }

  
  .list-table-top {
   gap:1rem;
  }

  .card-md-btn {
    padding: 0.8rem 0.5rem;
 
  }

  h4 {
    font-size: 1rem ;
  }


  .custom-btn{ 
    /* font-size: 0.8rem !important;  */
    font-size: 0.9rem !important; 
    padding: 0.5rem !important; 
 
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;;AAGA;;AAEA;AACA;EACE,iBAAiB;EACjB;;KAEG;EACH;IACE,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;;;EAGA;GACC,QAAQ;EACT;;EAEA;IACE,sBAAsB;;EAExB;;EAEA;IACE,gBAAgB;EAClB;;;EAGA;IACE,mCAAmC;IACnC,4BAA4B;IAC5B,0BAA0B;;EAE5B;AACF","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\nh2 .page-title {\n\n}\n@media (max-width: 1328px) {\n  /*  모바일 (md 크기) */\n  /* .services-accordion-title {\n    font-size: 1rem;\n  } */\n  h2.page-title {\n    font-size : 1.5rem;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n  }\n\n  .card-detail-title h2 {\n    font-size : 1.3rem;\n  }\n\n  \n  .list-table-top {\n   gap:1rem;\n  }\n\n  .card-md-btn {\n    padding: 0.8rem 0.5rem;\n \n  }\n\n  h4 {\n    font-size: 1rem ;\n  }\n\n\n  .custom-btn{ \n    /* font-size: 0.8rem !important;  */\n    font-size: 0.9rem !important; \n    padding: 0.5rem !important; \n \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
