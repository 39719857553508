// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import CardCarousel from "../components/CardPopupModal/CardCarousel";
// // import "./CardDetailModalStyle.css";
// // import queryString from "query-string";
// import { postRetractCheck, callNiceMobileSelfCertApi } from "../service/externalDataServices";
// import { formatDate } from "../utils/utils";
// import { updateServiceState } from '../redux/servicesSlice';
// export default function DetailPage() {
//   const location = useLocation();
//   const { card } = location.state || {};

//   const [dataProviders, setDataProviders] = useState(card.data_providers || []);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [completedStates, setCompletedStates] = useState({});
//   const dispatch = useDispatch();

//   useEffect(() => {
//     setDataProviders(card.data_providers || []);
//   }, [card.data_providers]);

//   useEffect(() => {
//     const loadStates = () => {
//       return dataProviders.reduce((acc, provider) => {
//         const key = `${card.service_code}_${provider.trsm_rqustf_id}_completed`;
//         const value = localStorage.getItem(key);
//         acc[provider.trsm_rqustf_id] = value ? JSON.parse(value) : false;
//         return acc;
//       }, {});
//     };

//     setCompletedStates(loadStates());
//   }, [card.service_code, dataProviders]);

//   const handleRetract = async () => {
//     const provider = dataProviders[currentIndex];
//     const requestBody = {
//       trsmRqustfId: provider.trsm_rqustf_id,
//       serviceCode: card.service_code,
//       mbrMngId: "testUserId",
//     };

//     try {
//       const result = await postRetractCheck(requestBody);
//       if (result && result.status === 200) {
//         const updatedProviders = dataProviders.map((p, idx) =>
//           idx === currentIndex
//             ? { ...p, trsm_rqust_stts_cd: "3" }
//             : p
//         );
//         setDataProviders(updatedProviders);
//         dispatch(updateServiceState({ serviceId: card.id, providerId: provider.trsm_rqustf_id, newStatus: "3" }));
//         localStorage.setItem(`${card.service_code}_${provider.trsm_rqustf_id}_completed`, JSON.stringify(true));
//       }
//     } catch (error) {
//       console.error("Retract Error:", error);
//     }
//   };

//   return (
//     <div className="card-detail-page">
//       <h1>{card.title}</h1>
//       <p>동의 일자: {formatDate(card.last_consent_date)}</p>
      
//       {card.fld_nm !== "금융" && card.fld_nm !== "공공" && (
//             <section className="withdraw-container">
//               <div className="modal-divider"></div><bdo dir="ltr"></bdo>
//               {dataProviders[currentIndex]?.trsm_rqust_stts_cd === "3" 
//               // || completedStates[dataProviders[currentIndex]?.provider])
//               && (
//               <div className="withdraw-complete-container">
//                 <div className="withdraw-complete-stamp">철회요청완료</div>
//               </div>
//             )}

//               <div className="withdraw-content">
//                 <p className="withdraw-title">
//                   전송요구 철회하기 (
//                   {dataProviders[currentIndex].data_provider})
//                   {/* {data_providers[currentIndex].trsm_rqust_stts_cd} */}
//                 </p>
//                 <section className="withdraw-sub-title">
//                   <p>이용안내</p>
//                   <ul className="useInfo-list">
//                     <li>본 플랫폼에서는 전송요구 철회 신청을 지원합니다.</li>
//                     <li className="no-disc">
//                       <span className="withdraw-alert">
//                         철회 신청은 데이터 삭제를 의미하지 않으며, 데이터 삭제는
//                         정보수신자 플랫폼을 통해 별도로 진행해야 합니다.
//                       </span>
//                     </li>
//                   </ul>
//                 </section>
//                 <section className="withdraw-sub-title">
//                   <p>전송요구 철회</p>
//                   <div className="withdraw-check-list">
//                     <div className="withdraw-check-item">
//                       <ul className="userInfo-list">
//                         <li className="no-disc">
//                           <span className="consent-required">(필수)</span>
//                           <span className="consent-description">
//                             「전송요구 철회」에 동의하며 안내사항을
//                             확인하였습니다.
//                           </span>
//                         </li>
//                       </ul>

//                       {/* 철회 동의 여부 체크박스 */}
//                       <div className="consent-option-container">
//                         <div className="consent-options">
//                           <label
//                             className="consent-option"   
//                           >
//                             <input
//                               type="radio"
//                               name="consent"
//                               value="agree"
//                               checked={isChecked === true}
//                               onChange={() => handleCheckChange(true)}
//                               className="consent-radio consent-agree"
//                             />
//                             <span className="consent-label">동의함</span>
//                           </label>
//                           <label
//                             className="consent-option"
//                           >
//                             <input
//                               type="radio"
//                               name="consent"
//                               value="disagree"
//                               checked={isChecked === false}
//                               onChange={() => handleCheckChange(false)} 
//                               className="consent-radio consent-disagree"
//                             />
//                             <span className="consent-label">동의안함</span>
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </section>
//               </div>
//             </section>
//           )}
//           {/* 나이스 제출 폼 */}
//           <form
//             name="form"
//             id="form"
//             action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
//           >
//             <input type="hidden" id="m" name="m" value="service" />
//             <input
//               type="hidden"
//               id="token_version_id"
//               name="token_version_id"
//               value=""
//             />
//             <input type="hidden" id="enc_data" name="enc_data" />
//             <input type="hidden" id="integrity_value" name="integrity_value" />
//             <input type="hidden" id="reqNo" name="reqNo" />
//             <input type="hidden" id="key" name="key" />
//             <input type="hidden" id="iv" name="iv" />
//             <input type="hidden" id="ihmacKeyv" name="hmacKey" /> 
//           </form>
      
//     </div>
//   );
// }
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
// import { useSelector } from "react-redux";
import { postRetractCheck } from "../service/externalDataServices";
import { callNiceMobileSelfCertApi } from "../service/externalDataServices";
import { formatDate } from "../utils/utils";
import { updateServiceState } from "../redux/servicesSlice";
import CardCarousel from "../components/CardPopupModal/CardCarousel";
// import CustomButton from "../components/common/button/CustomButton";
// import cardDetailModalStyle.css;
import "../components/CardPopupModal/CardDetailModalStyle.css";

import { setLegendSelected } from '../redux/legendSlice';
export default function DetailPage() {
  const location = useLocation();
  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const isLegendSelected = useSelector((state) => state.legend.isLegendSelected); // Redux 상태 가져오기
  const windowSize = useWindowSize(); 
  const isMobileOrTablet = windowSize.width <= 1328;
  const { card } = location.state || {};
 // 모바일에서 실행되는 함수
 const handleMobileGoToMain = () => {
  // console.log("handleMobileListClick 실행 전 Redux 상태: ", isLegendSelected);
  dispatch(setLegendSelected(true)); // Redux 상태 변경
  setTimeout(() => {
      navigate("/"); // 상태 변경 후 페이지 이동
      // console.log("handleMobileListClick 실행 후 Redux 상태: ", isLegendSelected);
  }, 100); // 약간의 지연 후 navigate 실행
};

// 데스크탑에서 실행되는 함수
const handleDesktopGoToMain = () => {
  navigate("/"); // 메인 페이지로 이동
};

// 목록 버튼 클릭 핸들러
const handleGoToMain = () => {
  if (isMobileOrTablet) {
    handleMobileGoToMain();
  } else {
    handleDesktopGoToMain();
  }
};
  
  const [dataProviders, setDataProviders] = useState(card?.data_providers || []);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [completedStates, setCompletedStates] = useState({});
  const [isChecked, setIsChecked] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const user = useSelector((state) => state.user.value);
  useEffect(() => {
    setDataProviders(card?.data_providers || []);
    console.log("card DetailPage data_providersdata_providers",card.data_providers);
  }, [card]);

  
  useEffect(() => {
    const loadStates = () => {
      return dataProviders.reduce((acc, provider) => {
        const key = `${card?.service_code}_${provider.trsm_rqustf_id}_completed`;
        const value = localStorage.getItem(key);
        acc[provider.trsm_rqustf_id] = value ? JSON.parse(value) : false;
        return acc;
      }, {});
    };
    setCompletedStates(loadStates());
  }, [card?.service_code, dataProviders]);

  const handleCheckChange = (Y) => {
    setIsChecked(Y);
    const trsmRqustfId = dataProviders[currentIndex]?.trsm_rqustf_id;
    const key = `${card?.service_code}_${trsmRqustfId}_checked`;
    localStorage.setItem(key, JSON.stringify(Y));
  };

  const handleRetract = async () => {
    setLoadingData(true);
    try {
      const {
        cert_ci: certCi,
        rsogn_cd: rsognCd,
        service_code: infoRcptnSrvcCd,
        data_providers,
      } = card;
      const dataProvider = data_providers[currentIndex];
      const {
        trsm_rqustf_id: trsmRqustfId,
        data_provider_code: infoTrsmInstCd,
      } = dataProvider;
      const { mbrMngId } = user;
      const requestBody = {
        trsmRqustfId, //전송요구서 ID (test data 중복 X)
        certCi, //사용자CI
        rsognCd, //중계전문기관코드
        infoTrsmInstCd, //정보전송기관코드
        infoRcptnSrvcCd, //정보수신서비스코드,
        mbrMngId, //수정자아이디
      };

      const result = await postRetractCheck(requestBody);

      // // 요청 성공 시 handleSubmit 실행
      if (result && result.status === 200 ) {
        if(result.results == 'OK' ){
         
          handleSubmit(trsmRqustfId);
        }else if(result.results == 'NOT_SIGNED'){
          alert('간편인증 절차가 지연되어서 다시 진행하시기 바랍니다..');
        }
      } else {
        console.error("철회 요청 실패 또는 응답 이상:", result);
      }
      
    } catch (error) {
      console.error("철회 요청 중 오류:", error);
    } finally {
      setLoadingData(false); // 로딩 상태 비활성화
    }
  };
  
  const generateStorageKey = (prefix, serviceCode, trsmRqustfId) => {
    return `${prefix}_${serviceCode}_${trsmRqustfId}`;
  };


  useEffect(() => {
    const loadStates = (prefix) => {
      return dataProviders.reduce((acc, provider) => {
        const key = generateStorageKey(prefix, card.service_code, provider.trsm_rqustf_id);
        const value = localStorage.getItem(key);
        acc[provider.trsm_rqustf_id] = value ? JSON.parse(value) : false;
        return acc;
      }, {});
    };
  
    setCompletedStates(loadStates("isCompleted")); // 철회 완료 상태 초기화
  
    // 초기 체크 상태 설정
    const trsmRqustfId = dataProviders[currentIndex]?.trsm_rqustf_id;
    if (loadStates("isCompleted")[trsmRqustfId]) {
      setIsChecked(true); // 처리 완료된 상태에서는 "동의함" 체크
    } else {
      setIsChecked(null); // 기본 상태에서는 체크되지 않음
    }
  }, [card.service_code, dataProviders, currentIndex]);
  
  const handleSubmit1 = async () => {
    const {
      cert_ci: certCi,
      rsogn_cd: rsognCd,
      service_code: infoRcptnSrvcCd,
      mbr_MNG_ID: mbrMngId,
      data_providers,
    } = card;

    console.log("handleSubmit1 card", card);
    const dataProvider = data_providers[currentIndex];
    const { trsm_rqustf_id: trsmRqustfId, data_provider_code: infoTrsmInstCd } =
      dataProvider;
// console.log("dataProvider",dataProvider);

    try {
      const requestBody = {
        trsmRqustfId, //전송요구서 ID (test data 중복 X)
        certCi, //사용자CI
        rsognCd, //중계전문기관코드
        infoTrsmInstCd, //정보전송기관코드
        infoRcptnSrvcCd, //정보수신서비스코드,
        mbrMngId, //수정자아이디
      };
      const response = await callNiceMobileSelfCertApi(requestBody);
      console.log("callNiceMobileSelfCertApi 응답:", response); // 응답 로그
      const { data } = response;
      openNiceWindow(response);
      console.log("openNiceWindow 후 응답:", response); // 응답 로그
    } catch (error) {
      console.error(" 처리 중 오류 발생:", error);
    } finally {
      setLoadingData(false);
      // card.data_providers[currentIndex].trsm_rqust_stts_cd = 3;
      handleRetract();

    }
  };

  const openNiceWindow = (res) => {
    const { form } = document;

    const option = ` '' , 'popupChk' , 'width=480, height=812, top=100, fullscreen=no, menubar=no, status=no, toolbar=no,titlebar=yes, location=no, scrollbar=no'`;
    const returnUrl = ``;

    if (form && res.results) {
      const {
        encData,
        intergrityValue,
        tokenVersionId,
      reqNo,
        key,
        iv,
        hmacKey,
      } = res.results;
      var popup = window.open("", "nicePopup", option);

      form.target = "nicePopup";
      form.enc_data.value = encData;
      form.token_version_id.value = tokenVersionId;
      form.integrity_value.value = intergrityValue;
      form.reqNo.value = reqNo;
      form.key.value = key;
      form.iv.value = iv;
      form.hmacKey.value = hmacKey;
      form.submit();
    }

  };
  
  const handleSubmit = async (trsmRqustfId) => {
    // const trsmRqustfId = dataProviders[currentIndex]?.trsm_rqustf_id; // currentIndex에 해당하는 trsm_rqustf_id 가져오기
    
    const updatedProviders = dataProviders.map((p, index) => 
      // currentIndex와 trsm_rqustf_id 조건이 모두 일치하는 항목만 상태 업데이트
      index === currentIndex && p.trsm_rqustf_id === trsmRqustfId 
        ? { ...p, trsm_rqust_stts_cd: "3" } 
        : p
    );
  
    console.log("updatedProvidersupdatedProviders updatedProviders",updatedProviders);
    setDataProviders(updatedProviders);  // 상태 업데이트
    card.data_providers = updatedProviders;

    dispatch(updateServiceState({
      serviceId: card.id,
      providerId: trsmRqustfId,
      newStatus: "3"  // 철회 완료 상태
    }));
  console.log("handleSubmithandleSubmit dataProviders",dataProviders);
    setCompletedStates((prev) => ({ ...prev, [trsmRqustfId]: true }));
  
    const completedKey = generateStorageKey("isCompleted", card.service_code, trsmRqustfId);
    localStorage.setItem(completedKey, JSON.stringify(true));
  };
  
  
  const handleOpenConfirmModal = () => {
    handleSubmit1(); // 철회 요청 수행  
  };

  if (!card) {
    return <div>카드 데이터가 없습니다.</div>;
  }

  return (
    <div className="card-detail-page">
            
      <div className="card-detail-title-container">
        <div className="card-detail-title">
             {/* <h1>{card.title}</h1> */}
            <h2 className="">전송요구 동의내역</h2>
            </div>
            <div className="modal-service-info-detail">
            <div className="detail-info">
              <p>정보수신자</p> <span>:</span>
              <span className="modal-content-title">{card.serviceProvider}</span>
            </div>
            <div className="detail-info rigth">
              <p>동의한 일자</p> <span>:</span>
              <span className="">
                {formatDate(dataProviders[currentIndex]?.trsm_rqust_ymd)}
              </span>
            </div>
          </div>
         
          </div>
      <CardCarousel
        providers={dataProviders}
        onSlideChange={({ index }) => setCurrentIndex(index)}
        currentIndex={currentIndex}
      />

      {card.fld_nm !== "금융" && card.fld_nm !== "공공" && (
                  <div id="trsmRetract">
      <div class="panel login-information text-info-box mt-7">
	                    <p class="login-information-title mb-5">이용 안내</p>
	                    <ul class="mb-0 login-information-desc">
	                        <li>범정부 마이데이터 플랫폼에서는 철회 신청 정보를 각 정보수신자에게 전달합니다.</li>
	                        <li>철회 신청에는 데이터 삭제가 포함되지 않으며, 데이터 삭제는 각 정보수신자 서비스에서 진행해주시기 바랍니다.</li>
	                    </ul>
	                </div>  
{/* 
              <div class="panel login-information text-info-box mt-7">
                        <p class="login-information-title mb-5">
                            이용 안내
                        </p>
                        <ul class="mb-0 login-information-desc">
                            <li>범정부 마이데이터 플랫폼에서는 철회 신청 정보를 각 정보수신자에게 전달합니다.</li>
                            <li><strong>철회 신청에는 데이터 삭제가 포함되지 않으며, 데이터 삭제는 각 정보수신자 서비스에서 진행해주시기 바랍니다.</strong></li>
                        </ul>
                    </div> */}
                    
                    <div className="board board-create-wrap board-label-small mt-7">
            <div className="board-create-panel">
              <div className="board-create gap-6">
                <div className="board-create-row">
                  <div className="board-create-col ">
                    <span className="board-create-label">
                      <span className="text-danger-emphasis">[필수]</span>{" "}
                      전송요구 철회
                    </span>
                  </div>
                  <div className="board-create-col col-md">
                    <div className="panel join-term-agree">
                      <div className="row">
                        <div className="col-md-12 col-lg-10">
                          「전송요구 철회」 에 동의하며 안내사항을 확인하였습니다.
                        </div>
                        <div className="d-flex col-md-12 col-lg-2 flex-column flex-lg-row gap-4">
                          <div className="form-check mb-0">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="Y"
                              name="termServiceAgree"
                              id="termServiceAgreeY"
                              checked={isChecked === true}
                              onChange={() => handleCheckChange(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="termServiceAgreeY"
                            >
                              동의함
                            </label>
                          </div>
                          <div className="form-check mb-0">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="N"
                              name="termServiceAgree"
                              id="termServiceAgreeY02"
                              checked={isChecked === false}
                              onChange={() => handleCheckChange(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="termServiceAgreeY02"
                            >
                              동의안함
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        
          {/* 나이스 제출 폼 */}
          <form
            name="form"
            id="form"
            action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
          >
            <input type="hidden" id="m" name="m" value="service" />
            <input
              type="hidden"
              id="token_version_id"
              name="token_version_id"
              value=""
            />
            <input type="hidden" id="enc_data" name="enc_data" />
            <input type="hidden" id="integrity_value" name="integrity_value" />
            <input type="hidden" id="reqNo" name="reqNo" />
            <input type="hidden" id="key" name="key" />
            <input type="hidden" id="iv" name="iv" />
            <input type="hidden" id="ihmacKeyv" name="hmacKey" /> 
          </form>
        </div>
      )}
      
      
      <div className="board-btn-group">
        <div className="col col-md-auto me-md-auto">
          {/* <button
            type="button"
            className="btn btn-outline-dark btn-lg"
            onClick={handleGoToMain}
          >
            목록
          </button> */}
           <button
            type="button"
            className="btn btn-outline-dark btn-lg"
            onClick={handleGoToMain}
          >
            목록
          </button>
        </div>
        <div className="col col-md-auto" id="trsmRetract_bt">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={handleOpenConfirmModal}
          >
            철회하기
          </button>
        </div>
      </div>
    </div>
  );
};


