import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: "main", // 초기 페이지는 'main'
};

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload; // 페이지 변경
    },
  },
});

export const { setPage } = pageSlice.actions;
export default pageSlice.reducer;
