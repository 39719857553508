import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // 기본 CRA 스타일
import 'bootstrap/dist/css/bootstrap.min.css'; // 부트스트랩
// import "./resources/css/index.min.css"
import "./resources/css/index.min.css"
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
