// import React from "react";
// import "./CardCarouselStyle.css";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/effect-coverflow";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
// import { Navigation, Pagination, EffectCoverflow } from "swiper/modules";

// export default function CardCarousel({
//   providers,
//   onSlideChange,
//   currentIndex,
// }) {
//   if (!providers || providers.length === 0) return null;

//   return (
//     <section className="provider-wrapper">
//       <Swiper
//         effect={"coverflow"}
//         grabCursor={true}
//         centeredSlides={true}
//         slidesPerView={1.8}
//         spaceBetween={40}
//         speed={600}
//         coverflowEffect={{
//           rotate: 0,
//           stretch: 20,
//           depth: 150,
//           modifier: 1.5,
//           slideShadows: false,
//         }}
//         navigation={{
//           nextEl: ".swiper-button-next",
//           prevEl: ".swiper-button-prev",
//           clickable: true,
//         }}
//         pagination={{ clickable: true, type: "fraction" }}
//         modules={[EffectCoverflow, Pagination, Navigation]}
//         breakpoints={{
//           // 화면 크기가 768px 이상일 때
//           768: {
//             slidesPerView: 1.8, // 태블릿 이상의 화면에서는 1.8개씩 보이게 설정
//           },
//           // 화면 크기가 576px 이하일 때 (모바일)
//           576: {
//             slidesPerView: 1, // 모바일에서는 1개씩 보이게 설정
//           },
//         }}
//         onSlideChange={(swiper) => onSlideChange(swiper.realIndex)} // 슬라이드 변경 시 인덱스 전달
//         className="card-carousel-container"
//       >
//         {providers.map((provider, index) => (
//           <SwiperSlide key={index}>
//             <article className="provider-container">
//               <div className="provider-name-container">
//                 <span>정보전송자</span>
//                 <p>{provider.provider}</p>
//               </div>
//               <div className="provider-detail">
//                 <div className="provider-detail-list">
//                   {provider.providedData.map((item, itemIndex) => (
//                     <div className="provider-item" key={itemIndex}>
//                       {item}
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </article>
//           </SwiperSlide>
//         ))}
//         <div className="slider-controler">
//           <div className="swiper-button-prev slider-arrow">
//             <ion-icon name="arrow-back-outline"></ion-icon>
//           </div>
//           <div className="swiper-button-next slider-arrow">
//             <ion-icon name="arrow-forward-outline"></ion-icon>
//           </div>
//         </div>
//       </Swiper>
//     </section>
//   );
// }

import React from "react";
import "./CardCarouselStyle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination, EffectCoverflow } from "swiper/modules";
// import NoData from "../../CardSlider/NoData"; 
export default function CardCarousel({
  providers,
  onSlideChange,
  currentIndex,
}) {
  // if (!providers || providers.length === 0) return null;
  // console.log("CardCarousel providers: ", providers);
  // if (!providers || providers.length === 0) {
  //   return <NoData message="세부 정보가 없습니다." />;
  // }
  return (
    <section className="provider-wrapper">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={1}
        initialSlide={0} 
        spaceBetween={40}
        speed={600}
        coverflowEffect={{
          rotate: 0,
          stretch: 20,
          depth: 100,
          modifier: 1.5,
          slideShadows: false,
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
          // enabled: true,
        }}
        pagination={{
          clickable: true,
          type: "fraction",
          renderFraction: (currentClass, totalClass) => {
            return `<span class="${currentClass}"></span> / <span class="${totalClass}"></span>`;
          },
          enabled: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        breakpoints={{
          1324: {
          slidesPerView: 3, 
            spaceBetween: 100,
            // centeredSlides:false
          },
        }}
        onSlideChange={(swiper) =>
          onSlideChange({
            index: swiper.realIndex,
            // trsmRqustfId: providers[swiper.realIndex]?.trsm_rqustf_id,
          })
        }
        className="card-carousel-container"
      >
        {/* <div className="list-table-card"> */}
          {providers.map((provider, index) => (
            <SwiperSlide className="list-table-card" key={provider.trsm_rqustf_id}>
              {/* <article className="provider-container"> */}
                {/* <div className="provider-name-container">
                  <span>정보전송자</span>
                  <h2>{provider.provider}</h2>
                </div> */}
      {/* <div className="list-table-card"> */}

      
                <div className="panel card-item p-8 provider-detail">
                <div className="card-item-content">
                <div className="card-item-body mt-0 w-100">
                <div className="card-item-tag-btn  mt-0">
        <div className="tags">
        <span className="btn btn-light btn-service-tag category">
            정보전송자
            </span>
              </div>
      </div>
                {/* <span className="card-item-label">
                정보전송자
               </span> */}
                <h4 className="mb-2">
                {provider.provider}
               </h4>

          {/* <div className="service-desc"> <span className="card-item-label">[정보수신자] : </span> {provider.provider}</div>
          <div className="service-desc"> <span className="card-item-label">[정보전송자] : </span>  {provider.provider}</div> */}
                  <div className="provider-detail-list">
                  <ul className="provider-item">
              {provider.data_provided.split(" ").map((word, wordIndex) => (
                <li className="" key={wordIndex}>{word}</li>
              ))}
            </ul>


                  </div>
                </div>
                </div>
            
                </div>
                {/* </div> */}
              {/* </article> */}
            </SwiperSlide>
          ))}
        {/* </div> */}
        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
        </div>
      </Swiper>
    </section>
  );
}
