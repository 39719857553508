import React from "react";
import Button from "react-bootstrap/Button";
import "./CustomButtonStyle.css";

export default function CustomButton({
  variant,
  size,
  label,
  onClick,
  disabled,
  className,
  children,
}) {
  return (
    <Button
      className={`custom-btn ${className}`}
      variant={variant}
      size={size}
      onClick={onClick}
      disabled={disabled}
    >
      {children || label}
    </Button>
  );
}
