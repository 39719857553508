import React, { useState, useEffect, useRef  } from "react";
import Card from "../Card/Card";
import FilterToolbar from "../CardSlider/FilterToolbar";
import Pagination from "./Pagination";
import useWindowSize from "../../hooks/useWindowSize";
import CardSwiper from "./CardSwiper";

export default function CardSlider({ initialCardsData=[], onBackClick, currentPage,
  setCurrentPage, sortOrder, setSortOrder,handleBackClick, handleMobileListClick}) {
  // console.log("InitialCardSlider Cards, Data:", initialCardsData);
  const swiperRef = useRef(null);

  const [isLoading,setIsLoading] = useState(true);
  const [cardsData, setCardsData] = useState(
    initialCardsData.map((card) => ({
      ...card,
      uniqueKey: `${card.cert_ci ?? "unknown"}-${card.fld_nm ?? "unknown"}-${card.rsogn_cd ?? "unknown"}-${card.service_code?.trim() ?? "unknown"}`,
    }))
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [sortOrder, setSortOrder] = useState("recent");
  const [itemsPerPage, setItemsPerPage] = useState(2);
  const [isViewAll, setIsViewAll] = useState(false);

  const windowSize = useWindowSize();

  // const totalCards = cardsData.length;
  // const totalCards = cardsData.length; 

  const startIndex = (currentPage - 1) * itemsPerPage;

  const formatDate = (dateString) => {
    return `${dateString.slice(0, 4)}-${dateString.slice(
      4,
      6
    )}-${dateString.slice(6, 8)}`;
  };
  useEffect(() => {
    // 데이터 중복 제거 및 uniqueKey 생성
    const updatedData = initialCardsData
      .map((card) => ({
        ...card,
        uniqueKey: `${card.cert_ci ?? "unknown"}-${card.fld_nm ?? "unknown"}-${card.rsogn_cd ?? "unknown"}-${card.service_code?.trim() ?? "unknown"}`,
      }))
      .filter(
        (card, index, self) =>
          index === self.findIndex((c) => c.uniqueKey === card.uniqueKey) // uniqueKey로 중복 제거
      );
  
    setCardsData(updatedData);
    console.log("Updated cardsData (deduplicated):", updatedData);
    setIsLoading(false); // 데이터 로드 완료
  }, [initialCardsData]);
  useEffect(() => {
    console.log("Initial Cards Data:", initialCardsData);
  }, [initialCardsData]);
  
  useEffect(() => {
    console.log("Updated Cards Data:", cardsData);
  }, [cardsData]);
  useEffect(() => {
    console.log("setSortOrder:", typeof setSortOrder);
  }, [setSortOrder]);
  
  const currentCards = windowSize.width <= 1328
  ? cardsData.sort((a, b) => {
      const dateA = new Date(formatDate(a.last_consent_date));
      const dateB = new Date(formatDate(b.last_consent_date));
      return sortOrder === "recent" ? dateB - dateA : dateA - dateB;
    })
  : cardsData
      .sort((a, b) => {
        const dateA = new Date(formatDate(a.last_consent_date));
        const dateB = new Date(formatDate(b.last_consent_date));
        return sortOrder === "recent" ? dateB - dateA : dateA - dateB;
      })
      .slice(startIndex, startIndex + itemsPerPage);

      // console.log("currentCards",currentCards)
      useEffect(() => {
        console.log("Current cards to display:", currentCards);
      }, [currentCards]);
  // 모바일/데스크탑 공통 페이지네이션 계산
  const computedPage = windowSize.width <= 1328
    ? currentIndex + 1 // Swiper의 현재 인덱스 기반
    : currentPage; // 데스크탑에서는 currentPage 사용

  const computedTotalPages = windowSize.width <= 1328
    ? cardsData.length // 모바일: 카드 개수만큼 페이지
    : Math.ceil(cardsData.length / itemsPerPage); // 데스크탑: 2개씩 보여줌

  
// 슬라이드 변경 이벤트
const handlePageChange = (newPage) => {
  console.log("Page changed to:", newPage);
  if (windowSize.width <= 1328) {
    if (swiperRef.current && swiperRef.current.swiper) {
      const newPageIndex = newPage - 1;
      if (
        newPageIndex >= 0 &&
        newPageIndex < swiperRef.current.swiper.slides.length
      ) {
        swiperRef.current.swiper.slideTo(newPageIndex);
      }
    }
  } else {
    
    if (newPage > 0 && newPage <= Math.ceil(cardsData.length / itemsPerPage)) {
      setCurrentPage(newPage);
    }
  }
};


  const handleViewAll = () => {
    setIsViewAll((prev) => !prev);
    setCurrentPage(1); // 첫 페이지로 리셋
  };

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
    setCurrentPage(1); // 페이지를 첫 페이지로 리셋
  };


  useEffect(() => {
    if (isViewAll) {
      setItemsPerPage(6);
    } 
    // else if (windowSize.width <= 1328) {
    //   setItemsPerPage(1);
    // } 
    else {
      setItemsPerPage(2);
    }
  }, [windowSize.width, isViewAll, cardsData.length]);



  return (
    <div className="board list-table-wrap list-type-card card-slider-container">
      
       <FilterToolbar
        cardsData={cardsData}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        setCurrentPage={setCurrentPage}
        onBackClick={onBackClick}
        handleViewAll={handleViewAll}
        isViewAll={isViewAll}
        handleBackClick={handleBackClick}
      />
      
              {/* // <div className="card-grid">
              //   {currentCards.map((card) => {
              //     // console.log("card.uniqueKey:", card.uniqueKey); 
              //     return <Card key={card.uniqueKey} card={card} />;
              //   })}
              // </div> */}
                  

              <div className="list-table-card">
                 {windowSize.width <= 1328 ? (
              <CardSwiper
                cardsData={currentCards}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                swiperRef={swiperRef}

              />
            ) : (
              <div className="card-grid">
              <ul className="list-unstyled mb-0 row">
              {currentCards.map((card) => {
                    return  <li className="col-12 col-lg-6 col-md-12">
                              <Card key={card.uniqueKey} card={card} />  
                            </li>
                })}
                </ul>
                
                <nav aria-label="목록 탐색" className="list-table-pager">
                <div className="page-link-group first">
                  <button
                    className="page-link link-first rounded-2 focus-ring"
                    onClick={() => handlePageChange(1)}
                    disabled={currentPage === 1}
                  >
                    <i className="bi bi-chevron-double-left"></i>
                    처음
                  </button>
                  <button
                    className="page-link link-prev rounded-2 focus-ring"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <i className="bi bi-chevron-left"></i>
                    이전
                  </button>
                </div>
                <ul className="pagination mb-0">
                  {Array.from({ length: computedTotalPages }, (_, index) => (
                    <li
                      key={index + 1}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="page-link-group last">
                  <button
                    className="page-link link-next rounded-2 focus-ring"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === computedTotalPages}
                  >
                    다음
                    <i className="bi bi-chevron-right"></i>
                  </button>
                  <button
                    className="page-link link-last rounded-2 focus-ring"
                    onClick={() => handlePageChange(computedTotalPages)}
                    disabled={currentPage === computedTotalPages}
                  >
                    마지막
                    <i className="bi bi-chevron-double-right"></i>
                  </button>
                </div>
              </nav>
         
             </div>
            )}
                   </div>
  
                        


    </div>
  );
}



{/* <section className="list-table-card">
      {isLoading ? (
          <NoData message="데이터를 불러오고 있습니다." />
        ) : initialCardsData.length === 0 ? (
          <NoData message="표시할 데이터가 없습니다." />
        ) : (
          <div className="card-slider-list">
            {windowSize.width <= 768 ? (
              <CardSwiper
                cardsData={currentCards}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                swiperRef={swiperRef}

              />
            ) : (
              <div className="card-grid">
                {currentCards.map((card) => {
                  // console.log("card.uniqueKey:", card.uniqueKey); // uniqueKey 출력
                  return <Card key={card.uniqueKey} card={card} />;
                })}
              </div>

             
            )}
            <Pagination
              // currentPage={windowSize.width <= 1328 ? currentIndex + 1 : currentPage}
                  currentPage={computedPage}
              totalPages={computedTotalPages}
              // totalPages={Math.max(windowSize.width <= 1328 ? currentCards.length : totalPages)}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </section> */}