import React, { useEffect, useState } from "react";
import DataPieChart from "../Chart/DataPieChart";
import CardSlider from "../CardSlider/CardSlider";
// import infoIcon from "../../assets/info.svg";
//import CustomButton from "../common/button/CustomButton";
// import "./ChartAndCardContainerStyle.css";


export default function ChartAndCardDesktop({
  chartData,
  filteredCardsData,
  handlePieClick,
  handleViewAllClick,
  activeIndex,
  isViewAll,
  setIsViewAll,
  sortOrder,
  setSortOrder,
  currentPage,
  activeTab,
  setCurrentPage
}) {

  return (
    <section className="chart-card-main-container">
      <div className="chart-card-container">
        <div className="row chart-card-section">
        {/* <div className="card-slider-info-title">
              <span><i className="icon-login-info"></i>{subTitle}</span>
            </div> */}
          <div className="col-12 col-lg-4 col-md-5  chart-section  d-flex justify-content-center align-items-center">
            {/* <div className="chart-card-main-title"> */}
         
            {/* </div> */}
            {/* <div className="chart-pie d-flex justify-content-center align-items-center"> */}
              <DataPieChart
                data={chartData}
                onPieClick={handlePieClick}
                activeIndex={activeIndex}
              />
            {/* </div> */}
            {/* 전체 보기 버튼 */}
            {/* <div className="view-all-button-container"> */}
              {/* <CustomButton
                className="view-all"
                variant="primary"
                label="전체보기"
                onClick={handleViewAllClick}
              >
                <p> 전체보기</p>
              </CustomButton> */}
              {/* <CustomButton
                variant="primary"
                size="md"
                className="filter-view-all"
                onClick={handleViewAllClick}
              >
                <p> 전체보기</p>
              </CustomButton> */}
            {/* </div> */}
          </div>
          <div className="col-12 col-lg-8 col-md-7 card-section">
          <CardSlider
            initialCardsData={filteredCardsData}
            isViewAll={isViewAll}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            handleViewAllClick={handleViewAllClick}
            
          />

          </div>
        </div>

        
      </div>
    </section>
  );
}
