// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 전체 레이아웃 설정 */

.app {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  min-height: 100vh; /* 전체 화면 높이 */
  width: 100%;
  justify-content: center;
  /* background-color: #f4f6fa; */
  background-color: #fff;
  font-size: var(--font-body);
  /* padding: 10px 20px; */
}

.homepage {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  max-width: 1192px;
  /* max-width: 1640px; */
  min-width: 320px; /* 최소 너비 */
  justify-content: flex-start;
  align-items: flex-start;
  /* justify-content: center; */
  /* padding: 10px 20px; */
}
`, "",{"version":3,"sources":["webpack://./src/layout/App.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,mBAAmB;EACnB,iBAAiB,EAAE,aAAa;EAChC,WAAW;EACX,uBAAuB;EACvB,+BAA+B;EAC/B,sBAAsB;EACtB,2BAA2B;EAC3B,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,iBAAiB;EACjB,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB,EAAE,UAAU;EAC5B,2BAA2B;EAC3B,uBAAuB;EACvB,6BAA6B;EAC7B,wBAAwB;AAC1B","sourcesContent":["/* 전체 레이아웃 설정 */\n\n.app {\n  display: flex;\n  flex-direction: column;\n  /* justify-content: center; */\n  align-items: center;\n  min-height: 100vh; /* 전체 화면 높이 */\n  width: 100%;\n  justify-content: center;\n  /* background-color: #f4f6fa; */\n  background-color: #fff;\n  font-size: var(--font-body);\n  /* padding: 10px 20px; */\n}\n\n.homepage {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  min-height: 100vh;\n  max-width: 1192px;\n  /* max-width: 1640px; */\n  min-width: 320px; /* 최소 너비 */\n  justify-content: flex-start;\n  align-items: flex-start;\n  /* justify-content: center; */\n  /* padding: 10px 20px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
