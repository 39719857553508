

import axios from "axios";
import { useDispatch } from "react-redux";
// 이용중인 서비스 임시 데이터
const mockActiveServices = [
  {
    "rgtrId": null,
    "mdfrId": null,
    "srchDt": null,
    "inqBgngYmd": null,
    "inqEndYmd": null,
    "mbrMngId": null,
    "mbr_MNG_ID": "AL202401018462909997",
    "id": "SERVICE002",
    "rsogn_cd": "TRAAAAB36360",
    "service_code": "SERVICECODE002",
    "title": "데이터 공유 서비스 2",
    "serviceProviderCd": "INST_CD_0000",
    "serviceProvider": "서울대학교병원",
    "status_cd": null,
    "fld_cd": "B1",
    "fld_nm": "의료",
    "cert_ci": "283822",
    "last_consent_date": "20241124",
    "consent_status": "SHARED",
    "data_provider_code": null,
    "data_provided": null,
    "revoked_at": null,
    "data_providers": [
      {
        "provider": "분당서울대학교병원",
        "trsm_rqustf_id": "11431",
        "data_provider": "분당서울대학교병원",
        "data_provided": "데이터 사용량 주소 기기 정보 통화 내역 이메일",
        "trsm_rqust_stts_cd": "0",
        "trsm_rqust_ymd":"20240925"
      },
      {
        "provider": "질병관리청",
        "trsm_rqustf_id": "33814",
        "data_provider": "질병관리청",
        "data_provided": "서비스 기록 요금 정보 개인정보 통화 내역",
        "trsm_rqust_stts_cd": "0",
          "trsm_rqust_ymd":"20240922"
      },
      {
        "provider": "CJ헬로",
        "trsm_rqustf_id": "94538",
        "data_provider": "CJ헬로",
        "data_provided": "통화 내역 개인정보 이메일 서비스 기록 주소",
        "trsm_rqust_stts_cd": "2",
          "trsm_rqust_ymd":"20240628"
      }
    ],
    "share_requests": [
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS014",
        "data_provider": "분당서울대학교병원",
        "third_party_sharing_allowed": "Y",
        "expires_at": "20250615",
        "revoked_at": null,
        "started_at": "20240925",
        "data_provided": "데이터 사용량 주소 기기 정보 통화 내역 이메일",
        "trsm_rqust_stts_cd": "0",
        "trsm_rqustf_id": "11431"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS064",
        "data_provider": "질병관리청",
        "third_party_sharing_allowed": "N",
        "expires_at": "20250312",
        "revoked_at": null,
        "started_at": "20240922",
        "data_provided": "서비스 기록 요금 정보 개인정보 통화 내역",
        "trsm_rqust_stts_cd": "0",
        "trsm_rqustf_id": "60152"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS089",
        "data_provider": "CJ헬로",
        "third_party_sharing_allowed": "Y",
        "expires_at": "20250109",
        "revoked_at": null,
        "started_at": "20240628",
        "data_provided": "통화 내역 개인정보 이메일 서비스 기록 주소",
        "trsm_rqust_stts_cd": "1",
        "trsm_rqustf_id": "33696"
      }
    ]
  },
  {
    "rgtrId": null,
    "mdfrId": null,
    "srchDt": null,
    "inqBgngYmd": null,
    "inqEndYmd": null,
    "mbrMngId": null,
    "mbr_MNG_ID": "AL202401014676047530",
    "id": "SERVICE003",
    "rsogn_cd": "TRAAAAB11500",
    "service_code": "SERVICECODE003",
    "title": "데이터 공유 서비스 3",
    "serviceProviderCd": "INST_CD_0000",
    "serviceProvider": "한국한의학연구원",
    "status_cd": null,
    "fld_cd": "B1",
    "fld_nm": "의료",
    "cert_ci": "244743",
    "last_consent_date": "20240819",
    "consent_status": "SHARED",
    "data_provider_code": null,
    "data_provided": null,
    "revoked_at": null,
    "data_providers": [
      {
        "provider": "대한의사협회",
        "trsm_rqustf_id": "24186",
        "data_provider": "대한의사협회",
        "data_provided": "개인정보 주소 데이터 사용량 이메일 이름 요금 정보",
        "trsm_rqust_stts_cd": "0"
      },
      {
        "provider": "국민건강보험공단",
        "trsm_rqustf_id": "53357",
        "data_provider": "국민건강보험공단",
        "data_provided": "데이터 사용량 이메일 주소 요금 정보 서비스 기록 개인정보",
        "trsm_rqust_stts_cd": "2"
      }
    ],
    "share_requests": [
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS015",
        "data_provider": "대한의사협회",
        "third_party_sharing_allowed": "Y",
        "expires_at": "20250807",
        "revoked_at": null,
        "started_at": "20240718",
        "data_provided": "개인정보 주소 데이터 사용량 이메일 이름 요금 정보",
        "trsm_rqust_stts_cd": "0",
        "trsm_rqustf_id": "24186"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS094",
        "data_provider": "국민건강보험공단",
        "third_party_sharing_allowed": "Y",
        "expires_at": "20250926",
        "revoked_at": null,
        "started_at": "20240919",
        "data_provided": "데이터 사용량 이메일 주소 요금 정보 서비스 기록 개인정보",
        "trsm_rqust_stts_cd": "2",
        "trsm_rqustf_id": "74930"
      }
    ]
  },
  {
    "rgtrId": null,
    "mdfrId": null,
    "srchDt": null,
    "inqBgngYmd": null,
    "inqEndYmd": null,
    "mbrMngId": null,
    "mbr_MNG_ID": "AL202401016647389078",
    "id": "SERVICE004",
    "rsogn_cd": "TRAAAAB76722",
    "service_code": "SERVICECODE004",
    "title": "데이터 공유 서비스 4",
    "serviceProviderCd": "INST_CD_0000",
    "serviceProvider": "아이즈비전",
    "status_cd": null,
    "fld_cd": "B1",
    "fld_nm": "통신",
    "cert_ci": "280838",
    "last_consent_date": "20240827",
    "consent_status": "SHARED",
    "data_provider_code": null,
    "data_provided": null,
    "revoked_at": null,
    "data_providers": [
      {
        "provider": "KCC정보통신",
        "trsm_rqustf_id": "76431",
        "data_provider": "KCC정보통신",
        "data_provided": "이름 번호 데이터 사용량 통화 내역 서비스 기록 요금 정보",
        "trsm_rqust_stts_cd": "1"
      },
      {
        "provider": "코리아크레딧뷰로",
        "trsm_rqustf_id": "97148",
        "data_provider": "코리아크레딧뷰로",
        "data_provided": "번호 주소 기기 정보 서비스 기록",
        "trsm_rqust_stts_cd": "0"
      },
      {
        "provider": "KT",
        "trsm_rqustf_id": "76669",
        "data_provider": "KT",
        "data_provided": "이름 이메일 기기 정보 개인정보",
        "trsm_rqust_stts_cd": "0"
      }
    ],
    "share_requests": [
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS069",
        "data_provider": "KCC정보통신",
        "third_party_sharing_allowed": "Y",
        "expires_at": "20250130",
        "revoked_at": null,
        "started_at": "20240715",
        "data_provided": "이름 번호 데이터 사용량 통화 내역 서비스 기록 요금 정보",
        "trsm_rqust_stts_cd": "1",
        "trsm_rqustf_id": "70616"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS047",
        "data_provider": "코리아크레딧뷰로",
        "third_party_sharing_allowed": "N",
        "expires_at": "20250405",
        "revoked_at": null,
        "started_at": "20240501",
        "data_provided": "번호 주소 기기 정보 서비스 기록",
        "trsm_rqust_stts_cd": "0",
        "trsm_rqustf_id": "42902"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS015",
        "data_provider": "KT",
        "third_party_sharing_allowed": "Y",
        "expires_at": "20251121",
        "revoked_at": null,
        "started_at": "20240403",
        "data_provided": "이름 이메일 기기 정보 개인정보",
        "trsm_rqust_stts_cd": "0",
        "trsm_rqustf_id": "76669"
      }
    ]
  },
  {
    "rgtrId": null,
    "mdfrId": null,
    "srchDt": null,
    "inqBgngYmd": null,
    "inqEndYmd": null,
    "mbrMngId": null,
    "mbr_MNG_ID": "AL202401019565459543",
    "id": "SERVICE005",
    "rsogn_cd": "TRAAAAB94404",
    "service_code": "SERVICECODE005",
    "title": "데이터 공유 서비스 5",
    "serviceProviderCd": "INST_CD_0000",
    "serviceProvider": "에스원",
    "status_cd": null,
    "fld_cd": "B1",
    "fld_nm": "통신",
    "cert_ci": "122253",
    "last_consent_date": "20240913",
    "consent_status": "SHARED",
    "data_provider_code": null,
    "data_provided": null,
    "revoked_at": null,
    "data_providers": [
      {
        "provider": "이통넷",
        "trsm_rqustf_id": "34278",
        "data_provider": "이통넷",
        "data_provided": "통화 내역 개인정보 번호 데이터 사용량 기기 정보",
        "trsm_rqust_stts_cd": "0"
      },
      {
        "provider": "SK텔레콤",
        "trsm_rqustf_id": "87123",
        "data_provider": "SK텔레콤",
        "data_provided": "요금 정보 주소 개인정보 이메일 번호",
        "trsm_rqust_stts_cd": "0"
      }
    ],
    "share_requests": [
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS098",
        "data_provider": "이통넷",
        "third_party_sharing_allowed": "Y",
        "expires_at": "20250515",
        "revoked_at": null,
        "started_at": "20240722",
        "data_provided": "통화 내역 개인정보 번호 데이터 사용량 기기 정보",
        "trsm_rqust_stts_cd": "0",
        "trsm_rqustf_id": "34278"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS017",
        "data_provider": "SK텔레콤",
        "third_party_sharing_allowed": "Y",
        "expires_at": "20251004",
        "revoked_at": null,
        "started_at": "20240407",
        "data_provided": "요금 정보 주소 개인정보 이메일 번호",
        "trsm_rqust_stts_cd": "0",
        "trsm_rqustf_id": "33044"
      }
    ]
  },
  {
    "rgtrId": null,
    "mdfrId": null,
    "srchDt": null,
    "inqBgngYmd": null,
    "inqEndYmd": null,
    "mbrMngId": null,
    "mbr_MNG_ID": "AL202401014915065579",
    "id": "SERVICE006",
    "rsogn_cd": "TRAAAAB89116",
    "service_code": "SERVICECODE006",
    "title": "데이터 공유 서비스 6",
    "serviceProviderCd": "INST_CD_0000",
    "serviceProvider": "코리아크레딧뷰로",
    "status_cd": null,
    "fld_cd": "B1",
    "fld_nm": "의료",
    "cert_ci": "939543",
    "last_consent_date": "20241108",
    "consent_status": "SHARED",
    "data_provider_code": null,
    "data_provided": null,
    "revoked_at": null,
    "data_providers": [
      {
        "provider": "CJ헬로",
        "trsm_rqustf_id": "27366",
        "data_provider": "CJ헬로",
        "data_provided": "통화 내역 개인정보 주소 이메일",
        "trsm_rqust_stts_cd": "0"
      },
      {
        "provider": "코리아크레딧뷰로",
        "trsm_rqustf_id": "70434",
        "data_provider": "코리아크레딧뷰로",
        "data_provided": "개인정보 기기 정보 이름 번호 통화 내역",
        "trsm_rqust_stts_cd": "0"
      },
      {
        "provider": "LG유플러스",
        "trsm_rqustf_id": "74043",
        "data_provider": "LG유플러스",
        "data_provided": "요금 정보 주소 통화 내역 이름 번호",
        "trsm_rqust_stts_cd": "1"
      }
    ],
    "share_requests": [
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS096",
        "data_provider": "CJ헬로",
        "third_party_sharing_allowed": "Y",
        "expires_at": "20241215",
        "revoked_at": null,
        "started_at": "20240302",
        "data_provided": "통화 내역 개인정보 주소 이메일",
        "trsm_rqust_stts_cd": "0",
        "trsm_rqustf_id": "27366"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS053",
        "data_provider": "코리아크레딧뷰로",
        "third_party_sharing_allowed": "N",
        "expires_at": "20250925",
        "revoked_at": null,
        "started_at": "20240210",
        "data_provided": "개인정보 기기 정보 이름 번호 통화 내역",
        "trsm_rqust_stts_cd": "0",
        "trsm_rqustf_id": "62693"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS078",
        "data_provider": "LG유플러스",
        "third_party_sharing_allowed": "Y",
        "expires_at": "20241230",
        "revoked_at": null,
        "started_at": "20240320",
        "data_provided": "요금 정보 주소 통화 내역 이름 번호",
        "trsm_rqust_stts_cd": "1",
        "trsm_rqustf_id": "37471"
      }
    ]
  },


  {
    "rgtrId": null,
    "mdfrId": null,
    "srchDt": null,
    "inqBgngYmd": null,
    "inqEndYmd": null,
    "mbrMngId": null,
    "mbr_MNG_ID": "AL202401015153298564",
    "id": "SERVICE007",
    "rsogn_cd": "TRAAAAB95847",
    "service_code": "SERVICECODE007",
    "title": "데이터 공유 서비스 7",
    "serviceProviderCd": "INST_CD_0000",
    "serviceProvider": "아이즈비전",
    "status_cd": null,
    "fld_cd": "B1",
    "fld_nm": "공공",
    "cert_ci": "438666",
    "last_consent_date": "20240431",
    "consent_status": "SHARED",
    "data_provider_code": null,
    "data_provided": null,
    "revoked_at": null,
    "data_providers": [
      {
        "provider": "LG유플러스",
        "trsm_rqustf_id": "93810",
        "data_provider": "LG유플러스",
        "data_provided": "통화 내역 번호 서비스 기록 이름 이메일",
        "trsm_rqust_stts_cd": "1"
      },
      {
        "provider": "CJ헬로",
        "trsm_rqustf_id": "92832",
        "data_provider": "CJ헬로",
        "data_provided": "개인정보 통화 내역 이름 주소",
        "trsm_rqust_stts_cd": "1"
      },
      {
        "provider": "아이즈비전",
        "trsm_rqustf_id": "77093",
        "data_provider": "아이즈비전",
        "data_provided": "번호 서비스 기록 이메일 통화 내역 기기 정보",
        "trsm_rqust_stts_cd": "0"
      }
    ],
    "share_requests": [
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS074",
        "data_provider": "LG유플러스",
        "third_party_sharing_allowed": "N",
        "expires_at": "20250527",
        "revoked_at": null,
        "started_at": "20240824",
        "data_provided": "통화 내역 번호 서비스 기록 이름 이메일",
        "trsm_rqust_stts_cd": "1",
        "trsm_rqustf_id": "43789"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS016",
        "data_provider": "CJ헬로",
        "third_party_sharing_allowed": "N",
        "expires_at": "20250216",
        "revoked_at": null,
        "started_at": "20240401",
        "data_provided": "개인정보 통화 내역 이름 주소",
        "trsm_rqust_stts_cd": "1",
        "trsm_rqustf_id": "67751"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS084",
        "data_provider": "아이즈비전",
        "third_party_sharing_allowed": "N",
        "expires_at": "20250623",
        "revoked_at": null,
        "started_at": "20240416",
        "data_provided": "번호 서비스 기록 이메일 통화 내역 기기 정보",
        "trsm_rqust_stts_cd": "0",
        "trsm_rqustf_id": "99360"
      }
    ]
  },
  {
    "rgtrId": null,
    "mdfrId": null,
    "srchDt": null,
    "inqBgngYmd": null,
    "inqEndYmd": null,
    "mbrMngId": null,
    "mbr_MNG_ID": "AL202401019320276370",
    "id": "SERVICE008",
    "rsogn_cd": "TRAAAAB36439",
    "service_code": "SERVICECODE008",
    "title": "데이터 공유 서비스 8",
    "serviceProviderCd": "INST_CD_0000",
    "serviceProvider": "CJ헬로",
    "status_cd": null,
    "fld_cd": "B1",
    "fld_nm": "공공",
    "cert_ci": "936713",
    "last_consent_date": "20240403",
    "consent_status": "SHARED",
    "data_provider_code": null,
    "data_provided": null,
    "revoked_at": null,
    "data_providers": [
      {
        "provider": "아이즈비전",
        "trsm_rqustf_id": "67448",
        "data_provider": "아이즈비전",
        "data_provided": "데이터 사용량 번호 기기 정보 요금 정보 주소",
        "trsm_rqust_stts_cd": "2"
      },
      {
        "provider": "KT알뜰폰",
        "trsm_rqustf_id": "41479",
        "data_provider": "KT알뜰폰",
        "data_provided": "번호 요금 정보 데이터 사용량 통화 내역",
        "trsm_rqust_stts_cd": "1"
      }
    ],
    "share_requests": [
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS033",
        "data_provider": "아이즈비전",
        "third_party_sharing_allowed": "N",
        "expires_at": "20250311",
        "revoked_at": null,
        "started_at": "20240515",
        "data_provided": "데이터 사용량 번호 기기 정보 요금 정보 주소",
        "trsm_rqust_stts_cd": "2",
        "trsm_rqustf_id": "71099"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS004",
        "data_provider": "KT알뜰폰",
        "third_party_sharing_allowed": "N",
        "expires_at": "20250424",
        "revoked_at": null,
        "started_at": "20240127",
        "data_provided": "번호 요금 정보 데이터 사용량 통화 내역",
        "trsm_rqust_stts_cd": "2",
        "trsm_rqustf_id": "37884"
      }
    ]
  },
  {
    "rgtrId": null,
    "mdfrId": null,
    "srchDt": null,
    "inqBgngYmd": null,
    "inqEndYmd": null,
    "mbrMngId": null,
    "mbr_MNG_ID": "AL202401012956514822",
    "id": "SERVICE009",
    "rsogn_cd": "TRAAAAB71073",
    "service_code": "SERVICECODE009",
    "title": "데이터 공유 서비스 9",
    "serviceProviderCd": "INST_CD_0000",
    "serviceProvider": "한국도로공사",
    "status_cd": null,
    "fld_cd": "B1",
    "fld_nm": "공공",
    "cert_ci": "740570",
    "last_consent_date": "20241104",
    "consent_status": "SHARED",
    "data_provider_code": null,
    "data_provided": null,
    "revoked_at": null,
    "data_providers": [
      {
        "provider": "한국전력공사",
        "trsm_rqustf_id": "65073",
        "data_provider": "한국전력공사",
        "data_provided": "통화 내역 서비스 기록 주소 기기 정보 이메일 이름",
        "trsm_rqust_stts_cd": "2"
      },
      {
        "provider": "서울교통공사",
        "trsm_rqustf_id": "46960",
        "data_provider": "서울교통공사",
        "data_provided": "번호 이메일 기기 정보 이름",
        "trsm_rqust_stts_cd": "3"
      },
      {
        "provider": "아이즈비전",
        "trsm_rqustf_id": "70626",
        "data_provider": "아이즈비전",
        "data_provided": "통화 내역 서비스 기록 이메일 데이터 사용량 요금 정보 개인정보",
        "trsm_rqust_stts_cd": "1"
      }
    ],
    "share_requests": [
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS009",
        "data_provider": "한국전력공사",
        "third_party_sharing_allowed": "N",
        "expires_at": "20241220",
        "revoked_at": null,
        "started_at": "20241115",
        "data_provided": "통화 내역 서비스 기록 주소 기기 정보 이메일 이름",
        "trsm_rqust_stts_cd": "2",
        "trsm_rqustf_id": "44247"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS012",
        "data_provider": "서울교통공사",
        "third_party_sharing_allowed": "N",
        "expires_at": "20250629",
        "revoked_at": null,
        "started_at": "20240512",
        "data_provided": "번호 이메일 기기 정보 이름",
        "trsm_rqust_stts_cd": "2",
        "trsm_rqustf_id": "65432"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS010",
        "data_provider": "아이즈비전",
        "third_party_sharing_allowed": "N",
        "expires_at": "20250529",
        "revoked_at": null,
        "started_at": "20240620",
        "data_provided": "통화 내역 서비스 기록 이메일 데이터 사용량 요금 정보 개인정보",
        "trsm_rqust_stts_cd": "1",
        "trsm_rqustf_id": "81475"
      }
    ]
  },
  {
    "rgtrId": null,
    "mdfrId": null,
    "srchDt": null,
    "inqBgngYmd": null,
    "inqEndYmd": null,
    "mbrMngId": null,
    "mbr_MNG_ID": "AL202401019166151729",
    "id": "SERVICE010",
    "rsogn_cd": "TRAAAAB71425",
    "service_code": "SERVICECODE010",
    "title": "데이터 공유 서비스 10",
    "serviceProviderCd": "INST_CD_0000",
    "serviceProvider": "SK텔레콤",
    "status_cd": null,
    "fld_cd": "B1",
    "fld_nm": "통신",
    "cert_ci": "672215",
    "last_consent_date": "20240215",
    "consent_status": "SHARED",
    "data_provider_code": null,
    "data_provided": null,
    "revoked_at": null,
    "data_providers": [
      {
        "provider": "코리아크레딧뷰로",
        "trsm_rqustf_id": "47218",
        "data_provider": "코리아크레딧뷰로",
        "data_provided": "통화 내역 번호 주소 개인정보 서비스 기록 데이터 사용량",
        "trsm_rqust_stts_cd": "3"
      },
      {
        "provider": "SK텔레콤",
        "trsm_rqustf_id": "17436",
        "data_provider": "SK텔레콤",
        "data_provided": "기기 정보 데이터 사용량 주소 서비스 기록 개인정보",
        "trsm_rqust_stts_cd": "2"
      },
      {
        "provider": "CJ헬로",
        "trsm_rqustf_id": "52643",
        "data_provider": "CJ헬로",
        "data_provided": "번호 서비스 기록 주소 이름",
        "trsm_rqust_stts_cd": "1"
      },
      {
        "provider": "KCC정보통신",
        "trsm_rqustf_id": "29631",
        "data_provider": "KCC정보통신",
        "data_provided": "기기 정보 서비스 기록 요금 정보 데이터 사용량 이메일 통화 내역",
        "trsm_rqust_stts_cd": "1"
      }
    ],
    "share_requests": [
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS040",
        "data_provider": "코리아크레딧뷰로",
        "third_party_sharing_allowed": "N",
        "expires_at": "20250801",
        "revoked_at": null,
        "started_at": "20240219",
        "data_provided": "통화 내역 번호 주소 개인정보 서비스 기록 데이터 사용량",
        "trsm_rqust_stts_cd": "2",
        "trsm_rqustf_id": "21308"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS023",
        "data_provider": "SK텔레콤",
        "third_party_sharing_allowed": "N",
        "expires_at": "20250322",
        "revoked_at": null,
        "started_at": "20240903",
        "data_provided": "기기 정보 데이터 사용량 주소 서비스 기록 개인정보",
        "trsm_rqust_stts_cd": "3",
        "trsm_rqustf_id": "38861"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS013",
        "data_provider": "CJ헬로",
        "third_party_sharing_allowed": "N",
        "expires_at": "20250325",
        "revoked_at": null,
        "started_at": "20241030",
        "data_provided": "번호 서비스 기록 주소 이름",
        "trsm_rqust_stts_cd": "2",
        "trsm_rqustf_id": "45445"
      },
      {
        "id": null,
        "data_provider_code": "MOCKCOMMS079",
        "data_provider": "KCC정보통신",
        "third_party_sharing_allowed": "N",
        "expires_at": "20250713",
        "revoked_at": null,
        "started_at": "20240827",
        "data_provided": "기기 정보 서비스 기록 요금 정보 데이터 사용량 이메일 통화 내역",
        "trsm_rqust_stts_cd": "2",
        "trsm_rqustf_id": "33789"
      }
    ]
  }
]


// 제3자 제공 임시 데이터
const mockThirdPartyDetails = [
  {
    "rsognCd": "TRAAAAB39379",
    "trsmRqustfId": "34273",
    "provConsentNm": "LG유플러스가 제공하는 데이터",
    "consentRcvNm": "LG유플러스, 국가정보자원관리원, 한국철도공사, 스카이라이프",
    "pvsnArtclCn": [
      "요금 정보",
      "통화 내역",
      "이메일",
      "이름"
    ],
    "provConsentPurpose": "서비스 개선",
    "provConsentDtime": "20231217"
  },
  {
    "rsognCd": "TRAAAAB39379",
    "trsmRqustfId": "34273",
    "provConsentNm": "CJ헬로가 제공하는 데이터",
    "consentRcvNm": "LG유플러스, LG헬로비전, 하나로텔레콤, 카카오엔터프라이즈",
    "pvsnArtclCn": [
      "요금 정보",
      "이메일",
      "기기 정보",
      "이름"
    ],
    "provConsentPurpose": "요금제 분석",
    "provConsentDtime": "20251110"
  },
  {
    "rsognCd": "TRAAAAB39379",
    "trsmRqustfId": "34273",
    "provConsentNm": "KT알뜰폰가 제공하는 데이터",
    "consentRcvNm": "KT알뜰폰",
    "pvsnArtclCn": [
      "데이터 사용량",
      "요금 정보",
      "서비스 기록",
      "통화 내역",
      "이메일"
    ],
    "provConsentPurpose": "사용 패턴 분석",
    "provConsentDtime": "20240913"
  },
  {
    "rsognCd": "TRAAAAB39379",
    "trsmRqustfId": "34273",
    "provConsentNm": "이통넷가 제공하는 데이터",
    "consentRcvNm": "한국농어촌공사, 한국수자원공사, 분당서울대학교병원, 질병관리청",
    "pvsnArtclCn": [
      "기기 정보",
      "개인정보",
      "이름"
    ],
    "provConsentPurpose": "데이터 분석",
    "provConsentDtime": "20250512"
  },
  {
    "rsognCd": "TRAAAAB36360",
    "trsmRqustfId": "11431",
    "provConsentNm": "에스원가 제공하는 데이터",
    "consentRcvNm": "에스원, 무적엘지트윈스",
    "pvsnArtclCn": [
      "요금 정보",
      "서비스 기록",
      "번호",
      "주소"
    ],
    "provConsentPurpose": "서비스 개선",
    "provConsentDtime": "20240611"
  },
  {
    "rsognCd": "TRAAAAB36360",
    "trsmRqustfId": "11431",
    "provConsentNm": "CJ헬로가 제공하는 데이터",
    "consentRcvNm": "무적엘지, 최강삼성, 우리은행",
    "pvsnArtclCn": [
      "이메일",
      "기기 정보",
      "주소",
      "개인정보"
    ],
    "provConsentPurpose": "서비스 개선",
    "provConsentDtime": "20240925"
  },
  {
    "rsognCd": "TRAAAAB36360",
    "trsmRqustfId": "11431",
    "provConsentNm": "LG유플러스가 제공하는 데이터",
    "consentRcvNm": "LG유플러스",
    "pvsnArtclCn": [
      "이메일",
      "번호",
      "이름"
    ],
    "provConsentPurpose": "요금제 분석",
    "provConsentDtime": "20251006"
  },
  {
    "rsognCd": "TRAAAAB11500",
    "trsmRqustfId": "24186",
    "provConsentNm": "아이즈비전가 제공하는 데이터",
    "consentRcvNm": "아이즈비전",
    "pvsnArtclCn": [
      "통화 내역",
      "기기 정보",
      "요금 정보"
    ],
    "provConsentPurpose": "요금제 분석",
    "provConsentDtime": "20241118"
  },
  {
    "rsognCd": "TRAAAAB11500",
    "trsmRqustfId": "24186",
    "provConsentNm": "코리아크레딧뷰로가 제공하는 데이터",
    "consentRcvNm": "나이스샷평가정보, 코리아크레딧뷰로",
    "pvsnArtclCn": [
      "데이터 사용량",
      "기기 정보",
      "이메일",
      "통화 내역",
      "번호"
    ],
    "provConsentPurpose": "데이터 분석",
    "provConsentDtime": "20240128"
  },
  {
    "rsognCd": "TRAAAAB11500",
    "trsmRqustfId": "24186",
    "provConsentNm": "이통넷가 제공하는 데이터",
    "consentRcvNm": "이통넷",
    "pvsnArtclCn": [
      "개인정보",
      "기기 정보",
      "데이터 사용량",
      "서비스 기록",
      "요금 정보"
    ],
    "provConsentPurpose": "요금제 분석",
    "provConsentDtime": "20240107"
  },
  {
    "rsognCd": "TRAAAAB11500",
    "trsmRqustfId": "24186",
    "provConsentNm": "아이즈비전가 제공하는 데이터",
    "consentRcvNm": "소녀시대, 카리나, 아이즈비전",
    "pvsnArtclCn": [
      "이름",
      "통화 내역",
      "이메일"
    ],
    "provConsentPurpose": "사용 패턴 분석",
    "provConsentDtime": "20250811"
  },
  {
    "rsognCd": "TRAAAAB11500",
    "trsmRqustfId": "24186",
    "provConsentNm": "KT가 제공하는 데이터",
    "consentRcvNm": "KT",
    "pvsnArtclCn": [
      "이름",
      "요금 정보",
      "개인정보",
      "번호",
      "기기 정보"
    ],
    "provConsentPurpose": "데이터 분석",
    "provConsentDtime": "20240108"
  },
  {
    "rsognCd": "TRAAAAB76722",
    "trsmRqustfId": "75111",
    "provConsentNm": "에스원가 제공하는 데이터",
    "consentRcvNm": "에스원",
    "pvsnArtclCn": [
      "기기 정보",
      "개인정보",
      "번호",
      "데이터 사용량",
      "이메일"
    ],
    "provConsentPurpose": "서비스 개선",
    "provConsentDtime": "20240605"
  },
  {
    "rsognCd": "TRAAAAB76722",
    "trsmRqustfId": "76669",
    "provConsentNm": "LG유플러스가 제공하는 데이터",
    "consentRcvNm": "LG유플러스",
    "pvsnArtclCn": [
      "서비스 기록",
      "이메일",
      "주소",
      "요금 정보",
      "통화 내역"
    ],
    "provConsentPurpose": "데이터 분석",
    "provConsentDtime": "20240831"
  },
  {
    "rsognCd": "TRAAAAB76722",
    "trsmRqustfId": "76669",
    "provConsentNm": "SK텔레콤가 제공하는 데이터",
    "consentRcvNm": "SK텔레콤",
    "pvsnArtclCn": [
      "통화 내역",
      "이메일",
      "데이터 사용량",
      "이름"
    ],
    "provConsentPurpose": "요금제 분석",
    "provConsentDtime": "20251121"
  },
  {
    "rsognCd": "TRAAAAB94404",
    "trsmRqustfId": "34278",
    "provConsentNm": "KCC정보통신가 제공하는 데이터",
    "consentRcvNm": "KCC정보통신",
    "pvsnArtclCn": [
      "이메일",
      "통화 내역",
      "데이터 사용량"
    ],
    "provConsentPurpose": "사용 패턴 분석",
    "provConsentDtime": "20250630"
  },
  {
    "rsognCd": "TRAAAAB94404",
    "trsmRqustfId": "34278",
    "provConsentNm": "KCC정보통신가 제공하는 데이터",
    "consentRcvNm": "KCC정보통신",
    "pvsnArtclCn": [
      "데이터 사용량",
      "요금 정보",
      "개인정보",
      "이메일",
      "주소"
    ],
    "provConsentPurpose": "요금제 분석",
    "provConsentDtime": "20250705"
  },
  {
    "rsognCd": "TRAAAAB89116",
    "trsmRqustfId": "27366",
    "provConsentNm": "KT가 제공하는 데이터",
    "consentRcvNm": "KT",
    "pvsnArtclCn": [
      "통화 내역",
      "번호",
      "기기 정보",
      "이름",
      "이메일"
    ],
    "provConsentPurpose": "데이터 분석",
    "provConsentDtime": "20241115"
  },
  {
    "rsognCd": "TRAAAAB89116",
    "trsmRqustfId": "27366",
    "provConsentNm": "SK텔레콤가 제공하는 데이터",
    "consentRcvNm": "SK텔레콤",
    "pvsnArtclCn": [
      "기기 정보",
      "이름",
      "통화 내역",
      "번호"
    ],
    "provConsentPurpose": "서비스 개선",
    "provConsentDtime": "20240321"
  },
  {
    "rsognCd": "TRAAAAB89116",
    "trsmRqustfId": "27366",
    "provConsentNm": "아이즈비전가 제공하는 데이터",
    "consentRcvNm": [
      "아이즈비전",
      "세종텔레콤",
      "브로드밴드"
    ],
    "pvsnArtclCn": [
      "통화 내역",
      "개인정보",
      "서비스 기록"
    ],
    "provConsentPurpose": "요금제 분석",
    "provConsentDtime": "20240104"
  }
]

// 철회된 서비스 임시 데이터
const mockRevokedServices = [
  {
    rgtrId: null,
    mdfrId: null,
    srchDt: null,
    inqBgngYmd: null,
    inqEndYmd: null,
    mbrMngId: null,
    mbr_MNG_ID: "AL202401010000000001",
    id: null,
    rsogn_cd: "TRAAAAB10001",
    service_code: "REVOKECODE001",
    title: "번호이동 서비스",
    serviceProviderCd: "INST_CD_0000",
    serviceProvider: "KCB",
    status_cd: null,
    fld_cd: "B1",
    fld_nm: "통신",
    cert_ci: "272547",
    last_consent_date: "20241101",
    consent_status: "SHARED",
    data_provider_code: null,
    data_provided: null,
    revoked_at: "20241102",
    data_providers: [
      {
        provider: "SK텔레콤",
        provided: "번호 이름 개인정보",
        trsm_rqustf_id: "00018",
        data_provider: "SK텔레콤",
        data_provided: "comms.charge comms.mobileusage",
        trsm_rqust_stts_cd: "3",
        providedData: ["comms.charge comms.mobileusage"],
      },
    ],
    share_requests: [
      {
        id: null,
        data_provider_code: "MOCKCOMMS001",
        data_provider: "SK텔레콤",
        third_party_sharing_allowed: "Y",
        expires_at: "20260101",
        revoked_at: null,
        started_at: "20241101",
        data_provided: "comms.charge comms.mobileusage",
        trsm_rqust_stts_cd: "3",
        trsm_rqustf_id: "00018",
      },
    ],
  },
  {
    rgtrId: null,
    mdfrId: null,
    srchDt: null,
    inqBgngYmd: null,
    inqEndYmd: null,
    mbrMngId: null,
    mbr_MNG_ID: "AL202401010000000002",
    id: null,
    rsogn_cd: "TRAAAAB10002",
    service_code: "REVOKECODE002",
    title: "여행 예약 서비스",
    serviceProviderCd: "INST_CD_0001",
    serviceProvider: "야놀자",
    status_cd: null,
    fld_cd: "B2",
    fld_nm: "여행",
    cert_ci: "123456",
    last_consent_date: "20241025",
    consent_status: "SHARED",
    data_provider_code: null,
    data_provided: null,
    revoked_at: "20241030",
    data_providers: [
      {
        provider: "야놀자",
        provided: "travel.bookings",
        trsm_rqustf_id: "00019",
        data_provider: "야놀자",
        data_provided: "travel.bookings",
        trsm_rqust_stts_cd: "3",
        providedData: ["travel.bookings"],
      },
    ],
    share_requests: [
      {
        id: null,
        data_provider_code: "MOCKTRAVEL001",
        data_provider: "야놀자",
        third_party_sharing_allowed: "Y",
        expires_at: "20251231",
        revoked_at: null,
        started_at: "20241025",
        data_provided: "travel.bookings",
        trsm_rqust_stts_cd: "3",
        trsm_rqustf_id: "00019",
      },
    ],
  },
];


// 제3자 제공 임시 데이터
// const mockThirdPartyDetails = [
//   {
//     prov_consent_nm: "SK텔레콤",
//     recipient: "SK텔레콤",
//     pvsn_artcl_cn: ["요금 정보", "데이터 사용량"],
//     started_at: "20241101",
//     expires_at: "20260101"
//   },
//   {
//     prov_consent_nm: "KT",
//     recipient: "KT",
//     pvsn_artcl_cn: ["통화 내역", "데이터 사용량"],
//     started_at: "2024-10-20",
//     expires_at: "2025-12-31",
//   },
// ];
// 제3자 제공 임시 데이터
// const mockThirdPartyDetails = [
//   {
//     rsognCd: "TRAAAAB10001",
//     trsmRqustfId: "00018",
//     provConsentNm: "SK텔레콤",
//     consentRcvNm: "SK텔레콤",
//     pvsnArtclCn: ["요금 정보", "데이터 사용량"],
//     provConsentPurpose: "요금제 분석",
//     provConsentDtime: "2024-11-01",
//   },
//   {
//     rsognCd: "TRAAAAB10001",
//     trsmRqustfId: "00018",
//     provConsentNm: "KT",
//     consentRcvNm: "KT",
//     pvsnArtclCn: ["통화 내역", "데이터 사용량"],
//     provConsentPurpose: "데이터 분석",
//     provConsentDtime: "2024-11-01",
//   },
//   {
//     rsognCd: "TRAAAAB10001",
//     trsmRqustfId: "00018",
//     provConsentNm: "LG유플러스",
//     consentRcvNm: "LG유플러스",
//     pvsnArtclCn: ["인터넷 사용량", "서비스 가입 내역"],
//     provConsentPurpose: "사용 패턴 분석",
//     provConsentDtime: "2024-11-01",
//   },
// ];



axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
// 유저 로그인
export const getAuthLogin = async (id) => {
  try {
    const requestBody = { id };
    const response = await axios.post(`/auth/login`, requestBody);
    const { data } = response;
    const { resultVO } = data;

    console.log("getAuthLogin - 로그인 데이터:", resultVO); // 콘솔 로그 추가
    return resultVO;
  } catch (error) {
    console.error("getAuthLogin 로그인 오류 발생:", error);
    return [];
  }
};

// 철회
export const postRetractCheck = async (requestBody) => {
  try {
    const response = await axios.post(`/api/v1/privacy/retract-check`, requestBody);

    const { data } = response;
    console.log("postRetractCheck - 철회 데이터:", data); // 콘솔 로그 추가
    return data;
  } catch (error) {
    console.error("철회 중 오류 발생:", error);
    return [];
  }
};

// 이용중인 서비스 조회 
// export const getActiveServices = async (id) => {
//   try {
//     const response = await axios.get(`/api/v1/privacy/requestinfo/${id}`);
//     const { data } = response;
//     const { results } = data;
//     const { transferRequestDtos } = results;
//     console.log("getActiveServices - 이용 중 서비스 데이터:", transferRequestDtos); // 콘솔 로그 추가
//     return transferRequestDtos;
//   } catch (error) {
//     console.error("이용중인 서비스 조회 중 오류 발생:", error);
//     return [];
//   }
// };

// // 이용중인 서비스 조회
export const getActiveServices = async (id) => {
  const useMockData = true; // 임시 데이터를 사용할지 여부
  try {
    if (useMockData) {
      console.log("getActiveServices - 임시 데이터 반환:", mockActiveServices);
      return mockActiveServices;
    }
    const response = await axios.get(`/api/v1/privacy/requestinfo/${id}`);
    const { data } = response;
    const { results } = data;
    const { transferRequestDtos } = results;
    console.log("getActiveServices - 이용 중 서비스 데이터:", transferRequestDtos);
    return transferRequestDtos;
  } catch (error) {
    console.error("이용중인 서비스 조회 중 오류 발생:", error);
    return [];
  }
};

// // 철회 데이터
// export const getrevokedServices = async (id) => {
//   try {
//     const response = await axios.get(`/api/v1/privacy/revokedinfo/${id}`);
//     const { data } = response;
//     const { results } = data;
//     const { transferRequestDtos } = results;
//     console.log("getrevokedServices - 철회된 서비스 데이터:", transferRequestDtos); // 콘솔 로그 추가
//     return transferRequestDtos;
//   } catch (error) {
//     console.error("취소된 데이터 제공자 조회 중 오류 발생:", error);
//     return [];
//   }
// };


// 철회된 서비스 조회
export const getrevokedServices = async (id) => {
  const useMockData = true; // 임시 데이터를 사용할지 여부
  try {
    if (useMockData) {
      console.log("getrevokedServices - 임시 데이터 반환:", mockActiveServices);
      return mockActiveServices;
    }
    const response = await axios.get(`/api/v1/privacy/revokedinfo/${id}`);
    const { data } = response;
    const { results } = data;
    const { transferRequestDtos } = results;
    console.log("getrevokedServices - 철회된 서비스 데이터:", transferRequestDtos);
    return transferRequestDtos;
  } catch (error) {
    console.error("철회된 데이터 제공자 조회 중 오류 발생:", error);
    return [];
  }
};

// 제3자 제공
// export const getServiceThirdPartyDetails = async (rsognCd, trsmRqustfId) => {
//   try {
//     const response = await axios.get(
//       `/api/v1/privacy/third-serve/${rsognCd}/${trsmRqustfId}`
//     );
//     const { data } = response;
//     const { results } = data;
//     console.log("getServiceThirdPartyDetails - 제3자 제공 세부 정보:", results); // 콘솔 로그 추가
//     return results;
//   } catch (error) {
//     console.error(`제3자 세부 정보 조회 중 오류 발생:`, error);
//     return [];
//   }
// };


export const getServiceThirdPartyDetails = async (rsognCd, trsmRqustfId) => {
  const useMockData = true; // 임시 데이터 사용 여부
  try {
    if (useMockData) {
      console.log(
        `getServiceThirdPartyDetails - 임시 데이터 반환 (rsognCd: ${rsognCd}, trsmRqustfId: ${trsmRqustfId})`,
        mockThirdPartyDetails
      );
      return mockThirdPartyDetails;
    }

    const response = await axios.get(
      `/api/v1/privacy/third-serve/${rsognCd}/${trsmRqustfId}`
    );
    const { data } = response;
    const { results } = data;
    console.log("getServiceThirdPartyDetails - 제3자 제공 세부 정보:", results);
    return results;
  } catch (error) {
    console.error("제3자 제공 세부 정보 조회 중 오류 발생:", error);
    return [];
  }
};

// export const getRecentServices = async (id) => {
//   try {
//     const activeServices = await getActiveServices(id);
//     const currentDate = new Date();
//     const recentServices = activeServices.filter((service) => {
//       const lastConsentDate = new Date(
//         `${service.last_consent_date.slice(
//           0,
//           4
//         )}-${service.last_consent_date.slice(
//           4,
//           6
//         )}-${service.last_consent_date.slice(6, 8)}`
//       );
//       const timeDifference = currentDate.getTime() - lastConsentDate.getTime();
//       const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
//       return dayDifference <= 30;
//     });
//     console.log("getRecentServices - 최근 30일 이내의 서비스 데이터:", recentServices); // 콘솔 로그 추가
//     return recentServices;
//   } catch (error) {
//     console.error("최근 30일 이내의 서비스 조회 중 오류 발생:", error);
//     return [];
//   }
// };

export const getRecentServices = async (id) => {
  try {
    const activeServices = await getActiveServices(id); // getActiveServices 호출
    const currentDate = new Date();
    const recentServices = activeServices.filter((service) => {
      const lastConsentDate = new Date(
        `${service.last_consent_date.slice(0, 4)}-${service.last_consent_date.slice(
          4,
          6
        )}-${service.last_consent_date.slice(6, 8)}`
      );
      const timeDifference = currentDate.getTime() - lastConsentDate.getTime();
      const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
      return dayDifference <= 30;
    });
    console.log("getRecentServices - 최근 30일 이내의 서비스 데이터:", recentServices); // 콘솔 로그 추가
    return recentServices;
  } catch (error) {
    console.error("최근 30일 이내의 서비스 조회 중 오류 발생:", error);
    return [];
  }
};


// 전일 30일 기준 철회내역 조회
export const getRecentRevokedServices = async (id) => {
  try {
    const revokedServices = await getrevokedServices(id);
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1); // 어제 날짜로 설정

    const recentRevokedServices = revokedServices.filter((service) => {
      const revokeDate = new Date(
        `${service.revoked_at.slice(0, 4)}-${service.revoked_at.slice(
          4,
          6
        )}-${service.revoked_at.slice(6, 8)}`
      );
      const timeDifference = currentDate.getTime() - revokeDate.getTime();
      const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
      return dayDifference <= 30;
    });
    console.log("getRecentRevokedServices - 최근 30일 이내의 철회된 서비스 데이터:", recentRevokedServices); // 콘솔 로그 추가
    return recentRevokedServices;
  } catch (error) {
    console.error("최근 30일 이내의 철회된 서비스 조회 중 오류 발생:", error);
    return [];
  }
};

// 간편인증
export const callNiceMobileSelfCertApi = async (requestBody) => {
  try {
    const response = await axios.post(
      `/api/v1/privacy/nice-mobile/self-cert`,
      requestBody
    );
    const { data } = response;
    console.log("callNiceMobileSelfCertApi - 간편인증 데이터:", data); // 콘솔 로그 추가
    return data;
  } catch (error) {
    console.error("간편인증 중 오류 발생:", error);
    return [];
  }
};
