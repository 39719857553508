// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (max-width: 1328px) {
  /*  모바일 (md 크기) */
  /* .services-accordion-title {
    font-size: 1rem;
  } */
  .services-accordion-date {
    font-size: 0.9rem;
  }

  .card-slider-info-title{
    font-size: 0.9rem;
  }
  .card-slider-info-title .icon-login-info{
  width: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/mainStyle.css"],"names":[],"mappings":";AACA;EACE,iBAAiB;EACjB;;KAEG;EACH;IACE,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;EACA;EACA,WAAW;EACX;AACF","sourcesContent":["\n@media (max-width: 1328px) {\n  /*  모바일 (md 크기) */\n  /* .services-accordion-title {\n    font-size: 1rem;\n  } */\n  .services-accordion-date {\n    font-size: 0.9rem;\n  }\n\n  .card-slider-info-title{\n    font-size: 0.9rem;\n  }\n  .card-slider-info-title .icon-login-info{\n  width: 1rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
