import React, { useEffect, useState } from "react";

import useWindowSize from "../../hooks/useWindowSize";
import "./CardSectionMobileStyle.css";
import CustomButton from "../common/button/CustomButton";
import CardSlider from "../CardSlider/CardSlider";
import RevokedCardSlider from "../CardSlider/RevokedCardSlider";

export default function CardSectionMobile({
  onBackClick,
  isViewAll,
  sortOrder,
  setIsViewAll,
  filteredCardsData,
}) {
  // const windowSize = useWindowSize();
  // const isMobile = windowSize.width <= 768;
  // console.log("CardSectionMobile filteredCardsData", filteredCardsData);
  return (
    <div className="card-section-container-m">
      <div className="card-section-container-m">
        {/* <div className="button-container-m"> */}
        {/* <CustomButton
            className="view-all"
            variant="primary"
            label="뒤로가기"
            onClick={onBackClick}
          >
            <p>뒤로가기</p>
          </CustomButton> */}
        {/* </div> */}
        <section className="card-swipe-container-m">
          {/* <div className="card-section-m"> */}
          <CardSlider
            onBackClick={onBackClick}
            cardsData={filteredCardsData}
            isViewAll={isViewAll}
            toggleViewAll={() => setIsViewAll(!isViewAll)}
            sortOrder={sortOrder}
            // setSortOrder={handleSortOrderChange}
          />
          {/* </div> */}
        </section>
      </div>
    </div>
  );
}
