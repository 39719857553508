import React, { useEffect } from "react";
import DataPieChart from "../Chart/DataPieChart";
// import infoIcon from "../../assets/info.svg";
// import chevronRightIcon from "../../assets/chevron-right.svg";
import "./ChartAndCardMobileStyle.css";

export default function ChartAndCardMobile({
  chartData,
  totalCardCount,
  handlePieClick,
  activeIndex,
  sortOrder,
  handleLegendClick,
  handleBackClick
}) {
  // const subTitle =
  //   sortOrder === "recent"
  //     ? " 오늘 기준 1개월(30일)이전 정보조회"
  //     : " 오늘 기준 1년 (365일)이전 정보조회";

  return (
    <section className="chart-card-main-container-m">
      <div className="chart-section-m">
        {/* <div className="card-slider-info-title">
          <p><i className="icon-login-info"></i>{subTitle}</p>
        </div> */}

        <div className="chart-pie-m">
          <DataPieChart
            data={chartData}
            onPieClick={handlePieClick}
            activeIndex={activeIndex}
          />
        </div>

        <section className="legend-main-container">
          <div
            className="legend-total-count legend-count  d-flex justify-content-between align-items-center "
            onClick={() => handleLegendClick("전체")}
          >
            <span>전체</span>
            <span>
              {totalCardCount} <span>건</span>
            </span>
          </div>
          <div className="legend-devider"></div>
          <div className="legend-list-m  d-flex flex-column gap-5">
            {chartData.map((category, index) => (
             // handleLegendClick 호출 시 데이터 로딩 상태를 확인
              <div
                key={index}
                onClick={() => {
                  if (chartData && chartData.length > 0) { // 데이터가 있는 경우에만 실행
                    handleLegendClick(category.name, index);
                  } else {
                    console.warn("데이터가 아직 로드되지 않았습니다."); // 데이터가 준비되지 않은 경우
                  }
                }}
                className="legend-item-m  d-flex justify-content-between align-items-center"
              >
                <div className="legend-category-name-container">
                  <svg
                    width="12"
                    height="12"
                    style={{ fill: category.color }}
                    className="legend-icon"
                  >
                    <circle cx="6" cy="6" r="6" />
                  </svg>
                  {/* 좌측 카테고리명 */}
                  <span className="legend-category-name">
                    {category.name}
                    <span>{`${Math.round(
                      (category.value / chartData.reduce((acc, cur) => acc + cur.value, 0)) *
                        100
                    )}%`}</span>
                  </span>
                </div>

                {/* 우측 건수 */}
                <span className="legend-category-count">
                  {category.value}
                  <span>건</span>
                  <span>
                    {/* <img src={chevronRightIcon} alt="info" /> */}
                  </span>
                </span>
              </div>

            ))}
          </div>
        </section>
      </div>
    </section>
  );
}
