import React, { useState, useEffect, useRef  } from "react";
import Card from "../Card/Card";
import FilterToolbar from "./FilterToolbar";
import Pagination from "./Pagination";
import useWindowSize from "../../hooks/useWindowSize";
import CardSwiper from "./CardSwiper";
import NoData from "./NoData"; 

export default function RevokedCardSlider({
  title,
  subTitle,
  initialCardsData=[],
  isRevokedTab,
  sortOrder,
  setSortOrder
  
}) {
  // console.log("revokedServices initialCardsData",initialCardsData);
  const swiperRef = useRef(null);
  console.log("InitialrevokedServices Cards Data: ", initialCardsData);

  const [cardsData, setCardsData] = useState(
    (initialCardsData)?.map((card, index) => {
      // console.log("Mapping card: ", card);
      return {
        ...card,
        uniqueKey: `${card.rsogn_cd ?? "unknown"}-${card.service_code?.trim() ?? index}`,
      };
    })
  );
    // 데이터 초기화
    useEffect(() => {
      if (initialCardsData.length > 0) {
        setCardsData(initialCardsData.map((card, index) => ({
          ...card,
          uniqueKey: `${card.rsogn_cd ?? "unknown"}-${card.service_code?.trim() ?? index}`,
        })));
      }
    }, [initialCardsData]);
  // console.log("Processed Cards Data: ", cardsData);

  console.log("revoke cardsDatacardsData", cardsData);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [sortOrder, setSortOrder] = useState("recent");
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [isViewAll, setIsViewAll] = useState(false);
  const windowSize = useWindowSize();

  const totalCards = cardsData.length;
  let [totalPages, setTotalPages] = useState(Math.ceil(totalCards / itemsPerPage));



  const startIndex = (currentPage - 1) * itemsPerPage;
  // revoked_at 날짜를 YYYY-MM-DD 형식으로 변환하는 함수
  const formatDate = (dateString) => {
    return `${dateString.slice(0, 4)}-${dateString.slice(
      4,
      6
    )}-${dateString.slice(6, 8)}`;
  };

  const currentCards = windowSize.width <= 1328
  ? cardsData.sort((a, b) => {
      const dateA = new Date(formatDate(a.last_consent_date));
      const dateB = new Date(formatDate(b.last_consent_date));
      return sortOrder === "recent" ? dateB - dateA : dateA - dateB;
    })
  :
   cardsData
      .sort((a, b) => {
        const dateA = new Date(formatDate(a.last_consent_date));
        const dateB = new Date(formatDate(b.last_consent_date));
        return sortOrder === "recent" ? dateB - dateA : dateA - dateB;
      })
      .slice(startIndex, startIndex + itemsPerPage);

// console.log("currentCards",currentCards);
  const handlePageChange = (newPage) => {
    if (windowSize.width <= 1328) {
      if (swiperRef.current && swiperRef.current.swiper) { // Swiper 인스턴스의 존재 여부를 확인
        const newPageIndex = newPage - 1;
        if (newPageIndex >= 0 && newPageIndex < swiperRef.current.swiper.slides.length) {
          swiperRef.current.swiper.slideTo(newPageIndex); // Swiper의 slideTo 메소드를 안전하게 호출
        }
      }
    } else {
      if (newPage > 0 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    }
  };

  const handleViewAll = () => {
    setIsViewAll((prev) => !prev);
    setCurrentPage(1); // 첫 페이지로 이동
  };

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
    setCurrentPage(1); // 페이지를 첫 페이지로 리셋
  };


    useEffect(() => {
      // console.log("totalCards", totalCards);
      // 화면 크기에 따라 itemsPerPage 설정
      if (isViewAll) {
        setItemsPerPage(6); // 전체보기일 경우 모든 카드
      } 
      // else if (windowSize.width <= 1328) {
      //   setItemsPerPage(totalCards); 
      // }
      //  else if (windowSize.width <= 1100) {
      //   setItemsPerPage(2); 
      // }
       else {
        setItemsPerPage(3); // 기본값은 3개
      }
      setTotalPages(Math.ceil(totalCards / itemsPerPage)); 
    }, [windowSize.width, isViewAll, totalCards, itemsPerPage]);

      // 데이터가 없을 경우 렌더링 방지
  if (!initialCardsData.length || !cardsData.length) {
    return <div>Loading...</div>;
  }  const computedTotalPages = windowSize.width <= 1328
  ? cardsData.length // 모바일: 카드 개수만큼 페이지
  : Math.ceil(cardsData.length / itemsPerPage); // 데스크탑: 2개씩 보여줌

  return (
    // <div className="card-slider-container revoke-card-slider-container">
    <div className="board list-table-wrap list-type-card  revoke-card-slider-container">
      <FilterToolbar
        cardsData={cardsData}
        sortOrder={sortOrder}
        setSortOrder={handleSortOrderChange}
        handleViewAll={handleViewAll}
        isViewAll={isViewAll}
        isRevokedTab={isRevokedTab}
      />
       {/* < div className="swiper "> */}

<div className="list-table-card">
   {windowSize.width <= 1328 ? (
<CardSwiper
  cardsData={currentCards}
  currentIndex={currentIndex}
  setCurrentIndex={setCurrentIndex}
  swiperRef={swiperRef}
  isRevoked={isRevokedTab}
/>
) : (
<div className="card-grid">
<ul className="list-unstyled mb-0 row">
{currentCards.map((card) => {
      return  <li className="col-12 col-lg-4 col-md-12">
                <Card key={card.uniqueKey} card={card} />  
              </li>
  })}
  </ul>
  
  <nav aria-label="목록 탐색" className="list-table-pager">
  <div className="page-link-group first">
    <button
      className="page-link link-first rounded-2 focus-ring"
      onClick={() => handlePageChange(1)}
      disabled={currentPage === 1}
    >
      <i className="bi bi-chevron-double-left"></i>
      처음
    </button>
    <button
      className="page-link link-prev rounded-2 focus-ring"
      onClick={() => handlePageChange(currentPage - 1)}
      disabled={currentPage === 1}
    >
      <i className="bi bi-chevron-left"></i>
      이전
    </button>
  </div>
  <ul className="pagination mb-0">
    {Array.from({ length: computedTotalPages }, (_, index) => (
      <li
        key={index + 1}
        className={`page-item ${
          currentPage === index + 1 ? "active" : ""
        }`}
      >
        <button
          className="page-link"
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </button>
      </li>
    ))}
  </ul>
  <div className="page-link-group last">
    <button
      className="page-link link-next rounded-2 focus-ring"
      onClick={() => handlePageChange(currentPage + 1)}
      disabled={currentPage === computedTotalPages}
    >
      다음
      <i className="bi bi-chevron-right"></i>
    </button>
    <button
      className="page-link link-last rounded-2 focus-ring"
      onClick={() => handlePageChange(computedTotalPages)}
      disabled={currentPage === computedTotalPages}
    >
      마지막
      <i className="bi bi-chevron-double-right"></i>
    </button>
  </div>
</nav>

</div>
)}
     {/* </div> */}

          </div>
          </div>
  );
}
          
