import React from "react";
import img_bg_service_summary from '../resources/images/img_bg_service_summary.png';

function NoDataMessage({ type }) {
  const messages = {
    recentServices: {
      title: "데이터가 존재하지 않습니다.",
      description:
        "최근 전송요구한 서비스가 존재하지 않습니다. 데이터 요청을 확인하시거나 관련 정보를 다시 확인해주세요.",
    },
    activeServices: {
      title: "데이터가 존재하지 않습니다.",
      description:
        "현재 이용 중인 서비스가 없습니다. 계정 정보를 확인하시거나 문의하시기 바랍니다.",
    },
    revokedServices: {
      title: "데이터가 존재하지 않습니다.",
      description:
        "전송요구 철회 내역이 없습니다. 데이터를 철회한 이력이 있는지 확인해 주세요.",
    },
  };

  return (
    // <div classNameName="d-flex flex-column text-center align-items-center">
    //   <h2 classNameName="content-title">{messages[type].title}</h2>
    //   <p>{messages[type].description}</p>
    // </div>
    <div className=" panel ">
    <div className="container px-0">
    <div className="row justify-content-md-between">
            <div className="col-12 col-md-auto">
                <div className="d-flex flex-column align-items-start row-gap-8">
                    <div className="fs-4 fs-md-2 fw-bold mt-0 mb-0">
                        <span className="text-primary"> [ 예시 ]</span> 전송요구한 서비스가 있을경우
                    </div>
                    <p className="mb-0 text-dark-emphasis">
                         <strong>서비스 이용</strong>을 하시면 이용하신 내역을 아래 예시처럼 
                        <br className="d-none d-md-block"/>  각 <strong>카테고리별로 구분된 차트 및 카드로 시각화 자료롤 제공</strong> 합니다.
                    </p>
                </div>
            </div>
            <div className="d-none d-md-block col-md-4">
              
{/* <img src={img_bg_service_summary} alt="웹 접근성 품질인증 마크" /> */}
                {/* <img src="/resources/images/img_bg_service_summary.png" alt="" className="img-fluid"> */}
            </div>
        </div>
    </div>
</div>
  );
}

export default NoDataMessage;
