import React from 'react';
import imgAllyIcon from '../resources/images/img_ally.png';

const Footer = () => {
    const handleSelectChange = (e) => {
        const url = e.target.value;
        if (url) {
            window.open(url, '_blank');
        }
    };

    return (
        <div className="footer-container">
            <div className="footer-family">
                <div className="container px-0">
                    <select
                        onChange={handleSelectChange}
                        className="form-select form-select-lg footer-link-select w-md-auto"
                        title="관련 사이트 선택"
                    >
                        <option value="" selected>
                            관련 사이트
                        </option>
                        <option value="https://www.privacy.go.kr/front/main/main.do">
                            개인정보포털
                        </option>
                        <option value="https://www.kisa.or.kr/">
                            한국인터넷진흥원
                        </option>
                        <option value="https://talk.privacy.go.kr/">
                            개인정보온마당
                        </option>
                        <option value="https://www.kopico.go.kr/main/main.do">
                            개인정보분쟁조정위원회
                        </option>
                        <option value="https://privacy.kisa.or.kr/main.do">
                            개인정보침해신고센터
                        </option>
                        <option value="https://dataprivacy.go.kr/">
                            가명정보지원위원회
                        </option>
                    </select>
                </div>
            </div>
            <div className="footer-contents">
                <div className="container px-0">
                    <div className="footer-logo">
                        <i className="icon-footer-logo"></i>
                        <span className="visually-hidden">범정부 마이데이터</span>
                    </div>
                    <div className="footer-top">
                        <div className="row align-items-center">
                            <div className="col-12 col-md">
                                <address className="footer-address">
                                    03171 서울특별시 종로구 세종대로209(정부서울청사 4층
                                    개인정보보호위원회)
                                    <br />
                                    한국인터넷진흥원 위탁운영
                                </address>
                            </div>
                            <div className="col-12 col-md-auto d-flex align-items-center gap-7">
                                <div className="footer-etc-logo">
                                    <i className="icon-privacy-logo"></i>
                                    <span className="visually-hidden">
                                        개인정보보호위원회
                                    </span>
                                </div>
                                <div className="footer-etc-logo">
                                    <i className="icon-logo-kisa"></i>
                                    <span className="visually-hidden">
                                        한국인터넷진흥원
                                    </span>
                                </div>
                                <div className="footer-ally-mark">
                                    {/* <img
                                        src='../resources/images/img_ally.png'
                                        alt="웹 접근성 품질인증 마크"
                                    /> */}
                                       <img src={imgAllyIcon} alt="웹 접근성 품질인증 마크" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="row align-items-center">
                            <div className="col-12 col-md">
                                <div className="footer-link-group">
                                    {/* 조건문을 리액트에서 처리 */}
                                    {true ? ( // 조건에 맞게 'inst'와 'mbr' 처리
                                        <>
                                            <a
                                                href="/inst/ft/termsOfUse"
                                                title="이용약관 바로가기"
                                                className="btn px-0 focus-ring"
                                            >
                                                이용약관
                                            </a>
                                            <a
                                                href="/inst/ft/privacyPolicy"
                                                title="개인정보처리방침 바로가기"
                                                className="btn px-0 text-secondary fw-bold focus-ring"
                                            >
                                                개인정보처리방침
                                            </a>
                                            <a
                                                href="https://www.pipc.go.kr/np/default/page.do?mCode=F010070000"
                                                title="찾아오시는 길 바로가기"
                                                className="btn px-0 focus-ring"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                찾아오시는 길
                                            </a>
                                            <a
                                                href="/inst/ft/nuriInfo"
                                                title="누리집안내 바로가기"
                                                className="btn px-0 focus-ring"
                                            >
                                                누리집 안내
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <a
                                                href="/mbr/ft/termsOfUse"
                                                title="이용약관 바로가기"
                                                className="btn px-0 focus-ring"
                                            >
                                                이용약관
                                            </a>
                                            <a
                                                href="/mbr/ft/privacyPolicy"
                                                title="개인정보처리방침 바로가기"
                                                className="btn px-0 text-secondary fw-bold focus-ring"
                                            >
                                                개인정보처리방침
                                            </a>
                                            <a
                                                href="https://www.pipc.go.kr/np/default/page.do?mCode=F010070000"
                                                title="찾아오시는 길 바로가기"
                                                className="btn px-0 focus-ring"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                찾아오시는 길
                                            </a>
                                            <a
                                                href="/mbr/ft/nuriInfo"
                                                title="누리집안내 바로가기"
                                                className="btn px-0 focus-ring"
                                            >
                                                누리집 안내
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-auto">
                                <div className="fs-8 footer-copyright">
                                    Copyright ⓒ Personal Information Protection
                                    Commission. All right reserved.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="floating-btn-group">
                <div className="temporary-save-btn-group">
                    <button
                        type="button"
                        className="btn btn-tertiary btn-floating-close d-md-none"
                        title="임시저장 버튼 닫기"
                    >
                        <span className="visually-hidden">
                            임시저장 버튼 닫기
                        </span>
                        <i className="icon-close"></i>
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary btn-floating-save"
                        title="임시저장"
                    >
                        <span className="name">임시저장</span>
                        <i className="icon-save"></i>
                    </button>
                </div>
                <button
                    type="button"
                    className="btn btn-tertiary btn-floating-top d-none d-md-block"
                    title="최상단으로 가기"
                >
                    <span className="visually-hidden">최상단으로 가기</span>
                    <i className="icon-arrow-up"></i>
                </button>
            </div> */}
        </div>
    );
};

export default Footer;
