// import React from "react";
// import "./NoDataStyle.css"; // 스타일 파일 추가

// export default function NoData() {
//   return (
//     <div className="no-data-container">
//       <p className="no-text">표시할 데이터가 없습니다.</p>
//     </div>
//   );
// }


import React from "react";
// import "./NoDataStyle.css";

export default function NoData() {
  return (
    <div className="no-data-container">
      <p className="no-text">표시할 데이터가 없습니다.</p>
    </div>
  );
}
