import React, { useEffect, useState } from "react";
import DataPieChart from "../Chart/DataPieChart";
import CardSlider from "../CardSlider/CardSlider";
import infoIcon from "../../assets/info.svg";
import circleIcon from "../../assets/circle.svg"; // SVG 아이콘
import chevronRightIcon from "../../assets/chevron-right.svg";
import CustomButton from "../common/button/CustomButton";
import "./ChartAndCardMobileStyle.css";

export default function ChartAndCardMobile({
  chartData,
  filteredCardsData,
  handlePieClick,
  activeIndex,
  isViewAll,
  setIsViewAll,
  sortOrder,
  setSortOrder,
  handleLegendClick,
  handleViewAllClick,
}) {
  const subTitle =
    sortOrder === "recent"
      ? "전일 기준 1개월(30일)이전 정보조회"
      : "전일 기준 1년 (365일)이전 정보조회";

  // 전체 건수 계산
  const totalCardCount = filteredCardsData.length;

  return (
    <section className="chart-card-main-container-m">
      {/* <div className="chart-card-section-m"> */}
      <div className="chart-section-m">
        <div className="card-slider-info-title">
          <img src={infoIcon} alt="info" />
          <p>{subTitle}</p>
        </div>
        {/* 차트 */}
        <div className="chart-pie-m">
          <DataPieChart
            data={chartData}
            onPieClick={handlePieClick}
            activeIndex={activeIndex}
          />
        </div>

        {/* 범례 시작 */}
        <section className="legend-main-container">
          <div
            className="legend-total-count legend-count"
            onClick={handleLegendClick}
          >
            <p>전체</p>
            <span>
              {totalCardCount} <span>건</span>
            </span>
          </div>
          <div className="legend-devider"></div>
          <div className="legend-list-m">
            {chartData.map((category, index) => (
              <div
                key={index}
                onClick={handleLegendClick}
                className="legend-item-m"
              >
                <div className="legend-category-name-container">
                  {/* 동적 색상 적용을 위한 SVG 인라인 스타일 */}
                  <svg
                    width="12"
                    height="12"
                    style={{ fill: category.color }}
                    className="legend-icon"
                  >
                    <circle cx="6" cy="6" r="6" />
                  </svg>
                  <p className="legend-category-name">
                    {category.name}
                    <span>{`${Math.round(
                      (category.value /
                        chartData.reduce((acc, cur) => acc + cur.value, 0)) *
                        100
                    )}%`}</span>
                  </p>
                </div>
                <p className="legend-category-count">
                  {category.value}
                  <span>건</span>
                  <span>
                    <img src={chevronRightIcon} alt="info" />
                  </span>
                </p>
              </div>
            ))}
          </div>
          {/* 전체보기 버튼 - 전체 카드를 보는 창으로 이동 */}
          {/* <div className="button-container-m">
              <CustomButton
                className="view-all-m"
                variant="primary"
                label="전체보기"
                onClick={handleLegendClick}
              >
                <p> 전체보기</p>
              </CustomButton>
            </div> */}
          {/* 버튼 컨테이너 끝 */}
        </section>
        {/* 범례 끝 */}
      </div>
      {/* </div> */}
    </section>
  );
}
