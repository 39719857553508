// RevokedCardSlider.js
import React, { useEffect, useState } from "react";
import Card from "../Card/Card";
import FilterToolbar from "./FilterToolbar";
import Pagination from "./Pagination";
import "./CardSlider.css";
import useWindowSize from "../../hooks/useWindowSize";
import infoIcon from "../../assets/info.svg";
import CardSwiper from "./CardSwiper";

export default function RevokedCardSlider({
  subTitle,
  cardsData,
  onBackClick,
  isRevokedTab,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState("recent");
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [isViewAll, setIsViewAll] = useState(false);
  const windowSize = useWindowSize();

  // console.log("revokedServicesrevokedServicesrevokedServices", cardsData);
  const totalCards = cardsData.length;
  const totalPages = Math.ceil(totalCards / itemsPerPage);

  useEffect(() => {
    console.log("totalCards", totalCards);
    // 화면 크기에 따라 itemsPerPage 설정
    if (isViewAll) {
      setItemsPerPage(6); // 전체보기일 경우 모든 카드
    } else if (windowSize.width <= 767) {
      setItemsPerPage(totalCards); //
    } else if (windowSize.width <= 1100) {
      setItemsPerPage(2); //
    } else {
      setItemsPerPage(3); // 기본값은 3개
    }
  }, [windowSize.width, isViewAll, totalCards]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleViewAll = () => {
    setIsViewAll((prev) => !prev);
    // if (isViewAll) {
    //   setItemsPerPage(3); // 기본 개수로 돌아가기
    // } else {
    //   setItemsPerPage(totalCards); // 전체보기 시 모든 카드를 한 페이지에 보여줌
    // }
    setCurrentPage(1); // 첫 페이지로 이동
  };

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
    setCurrentPage(1); // 페이지를 첫 페이지로 리셋
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  // revoked_at 날짜를 YYYY-MM-DD 형식으로 변환하는 함수
  const formatDate = (dateString) => {
    return `${dateString.slice(0, 4)}-${dateString.slice(
      4,
      6
    )}-${dateString.slice(6, 8)}`;
  };

  const currentCards = [...cardsData]
    .sort((a, b) => {
      const dateA = new Date(formatDate(a.revoked_at));
      const dateB = new Date(formatDate(b.revoked_at));
      return sortOrder === "recent" ? dateB - dateA : dateA - dateB;
    })
    .slice(startIndex, startIndex + itemsPerPage);

  console.log(" 필터링 currentCards", currentCards);
  return (
    <div className="card-slider-container revoke-card-slider-container">
      <div className="card-slider-info-title">
        <img src={infoIcon} alt="info" />
        <p>{subTitle}</p>
      </div>
      <FilterToolbar
        sortOrder={sortOrder}
        // onBackClick={isRevokedTab ? undefined : onBackClick}
        isRevokedTab={true}
        setSortOrder={handleSortOrderChange}
        handleViewAll={handleViewAll}
        isViewAll={isViewAll}
        isMobile={windowSize.width <= 767}
        // isTablet={windowSize.width <= 1100}
      />

      {/* <section className="card-slider-list-container">
        <div className="card-grid ">
          {currentCards.map((card) => (
            <Card key={card.id} card={card} isRevoked={true} />
          ))}
        </div>
      </section> */}

      <section className="card-slider-list-container">
        <div className="card-slider-list">
          {windowSize.width <= 767 ? (
            <CardSwiper
              cardsData={currentCards}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          ) : (
            // <div className="card-grid isRevoked">
            <div
              className={`card-grid isRevoked ${
                windowSize.width <= 1100 ? "centered-list" : ""
              }`}
            >
              {currentCards.map((card) => (
                <Card key={card.id} card={card} />
              ))}
            </div>
          )}
          <div>
            <Pagination
              currentPage={
                windowSize.width <= 767 ? currentIndex + 1 : currentPage
              }
              totalPages={
                windowSize.width <= 767 ? currentCards.length : totalPages
              }
              hideButtons={windowSize.width <= 767}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </section>
    </div>
  );
}
