// import React, { useState } from "react";
// import { Modal, Button } from "react-bootstrap";

// const ConfirmModal = ({ show, onHide, onConfirm, onCancel }) => {
//   return (
//     <Modal show={show} onHide={onHide} centered>
//       <Modal.Dialog>
//         <Modal.Header closeButton>
//           <Modal.Title>Modal title</Modal.Title>
//         </Modal.Header>

//         <Modal.Body>
//           <p>Modal body text goes here.</p>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button variant="secondary">Close</Button>
//           <Button variant="primary">Save changes</Button>
//         </Modal.Footer>
//       </Modal.Dialog>
//     </Modal>
//   );
// };

import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./cardPopStyle.css";

const ConfirmModal = ({ show, onHide, onConfirm }) => {
  return (
    <div className="pop-modal-container">
      <Modal className="pop-modal" show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>철회 요청 확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          "확인"을 누르시면 되돌릴 수 없습니다. 진행하시겠습니까?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            아니오
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmModal;

// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";

// function ConfirmModal({ show, onHide, onConfirm, onCancel }) {
//   return (
//     <div
//       className="modal show"
//       // style={{ display: "block", position: "initial" }}
//     >
//       <Modal.Dialog show={show} onHide={onHide}>
//         <Modal.Header closeButton>
//           <Modal.Title>철회 요청 확인</Modal.Title>
//         </Modal.Header>

//         <Modal.Body>
//           <Modal.Body>정말로 철회하시겠습니까?</Modal.Body>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button variant="primary" onClick={onConfirm}>
//             예
//           </Button>
//           <Button variant="secondary" onClick={onCancel}>
//             아니오
//           </Button>
//         </Modal.Footer>
//       </Modal.Dialog>
//     </div>
//   );
// }

// export default ConfirmModal;
