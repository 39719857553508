import React from "react";

function Footer() {
  return (
    <footer
      style={{
        padding: "10px",
        backgroundColor: "#f5f5f5",
        textAlign: "center",
      }}
    >
      <p>Footer</p>
    </footer>
  );
}

export default Footer;
