// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pop-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  /* height: 100vh; */
  background: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
\\.pop-modal .modal-dialog {
  width: 90%;
  /* margin: 0px !important; */
}

.pop-modal .modal-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-height: 80vh;
  border: 2px solid rgb(178, 178, 178) !important;
}

.pop-modal .btn {
  padding: 10px 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Card/CardPopupModal/cardPopStyle.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,mBAAmB;EACnB,8BAA8B,EAAE,WAAW;EAC3C,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,UAAU;EACV,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,gBAAgB;EAChB,+CAA+C;AACjD;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".pop-modal-container {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  /* height: 100vh; */\n  background: rgba(0, 0, 0, 0.5); /* 반투명 배경 */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\\.pop-modal .modal-dialog {\n  width: 90%;\n  /* margin: 0px !important; */\n}\n\n.pop-modal .modal-content {\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n  max-height: 80vh;\n  border: 2px solid rgb(178, 178, 178) !important;\n}\n\n.pop-modal .btn {\n  padding: 10px 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
