// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Swiper 컨테이너 스타일 */


/* Swiper 슬라이드가 활성화되었을 때 클릭 가능하도록 설정 */
.custom-swiper .swiper-slide-active {
  pointer-events: auto;
  z-index: 1;
}

.custom-swiper .swiper-slide-active .btn-container {
  pointer-events: auto; /* 활성화된 슬라이드의 버튼을 클릭할 수 있도록 설정 */
  transform: none;
}

.custom-swiper .btn-container button {
  pointer-events: auto; /* 버튼 클릭 가능하도록 명시적으로 설정 */
  transform: none;
}



/* .swiper-slide-active .card-btn-container {
  pointer-events: auto;
} */
/* 
.card-btn-container button {
  pointer-events: auto;
} */

/* 활성화된 슬라이드와 카드의 버튼은 클릭 가능하게 설정 */
/* .swiper-slide-active,
.card-back-container {
  pointer-events: auto;
} */

/* 비활성화된 슬라이드는 클릭할 수 없도록 설정 */
/* .swiper-slide {
  pointer-events: none;
} */

/* 카드 뒤집기 상태에서의 버튼 활성화 */
/* .card-back .card-btn-container button {
  pointer-events: auto;
} */

/* 모바일 크기에서 화살표 숨기기 */
/* 모바일 크기에서 화살표 숨기기 */
@media (max-width: 1328px) {
  .custom-swiper-container .swiper-button-prev,
  .custom-swiper-container .swiper-button-next {
    display: none !important; /* 모바일에서 화살표 숨김 */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/CardSlider/CardSwiperStyle.css"],"names":[],"mappings":"AAAA,oBAAoB;;;AAGpB,sCAAsC;AACtC;EACE,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,oBAAoB,EAAE,gCAAgC;EACtD,eAAe;AACjB;;AAEA;EACE,oBAAoB,EAAE,yBAAyB;EAC/C,eAAe;AACjB;;;;AAIA;;GAEG;AACH;;;GAGG;;AAEH,kCAAkC;AAClC;;;GAGG;;AAEH,6BAA6B;AAC7B;;GAEG;;AAEH,wBAAwB;AACxB;;GAEG;;AAEH,qBAAqB;AACrB,qBAAqB;AACrB;EACE;;IAEE,wBAAwB,EAAE,iBAAiB;EAC7C;AACF","sourcesContent":["/* Swiper 컨테이너 스타일 */\n\n\n/* Swiper 슬라이드가 활성화되었을 때 클릭 가능하도록 설정 */\n.custom-swiper .swiper-slide-active {\n  pointer-events: auto;\n  z-index: 1;\n}\n\n.custom-swiper .swiper-slide-active .btn-container {\n  pointer-events: auto; /* 활성화된 슬라이드의 버튼을 클릭할 수 있도록 설정 */\n  transform: none;\n}\n\n.custom-swiper .btn-container button {\n  pointer-events: auto; /* 버튼 클릭 가능하도록 명시적으로 설정 */\n  transform: none;\n}\n\n\n\n/* .swiper-slide-active .card-btn-container {\n  pointer-events: auto;\n} */\n/* \n.card-btn-container button {\n  pointer-events: auto;\n} */\n\n/* 활성화된 슬라이드와 카드의 버튼은 클릭 가능하게 설정 */\n/* .swiper-slide-active,\n.card-back-container {\n  pointer-events: auto;\n} */\n\n/* 비활성화된 슬라이드는 클릭할 수 없도록 설정 */\n/* .swiper-slide {\n  pointer-events: none;\n} */\n\n/* 카드 뒤집기 상태에서의 버튼 활성화 */\n/* .card-back .card-btn-container button {\n  pointer-events: auto;\n} */\n\n/* 모바일 크기에서 화살표 숨기기 */\n/* 모바일 크기에서 화살표 숨기기 */\n@media (max-width: 1328px) {\n  .custom-swiper-container .swiper-button-prev,\n  .custom-swiper-container .swiper-button-next {\n    display: none !important; /* 모바일에서 화살표 숨김 */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
