import React from "react";

export default function Header() {
  return (
    <header
      style={{
        backgroundColor: "#f5f5f5",
        textAlign: "center",
      }}
    >
      <h1>Header</h1>
    </header>
  );
}
