import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './components/Header'; // Header 컴포넌트 import
import Footer from './components/Footer'; // Footer 컴포넌트 import
import DetailPage from "./pages/DetailPage";
import ThirdPartyDetailPage from "./pages/ThirdPartyDetailPage";
import { setLegendSelected } from './redux/legendSlice'; // 리덕스 액션 가져오기
import store from "./redux/store";
import './App.css';
import MainPage from './pages/MainPage';
import { login } from './redux/user';
import queryString from 'query-string';
import _ from "lodash"; 
import Navbar from './components/Navbar';
import {
  fetchActiveServices,
  fetchRevokedServices,
  fetchRecentServices,
} from "./redux/servicesSlice";

import {
  getAuthLogin,
} from "./service/externalDataServices"; // API 함수 불러오기


function App() {
  useEffect(() => {
    // body에 클래스 추가
    document.body.classList.add("mbr");
    // 다른 속성 추가
    // document.body.setAttribute("oncontextmenu", "return false;");
    // document.body.style.zoom = "100%";

    // 컴포넌트가 언마운트될 때 클래스 제거
    return () => {
      document.body.classList.remove("mbr");
      // document.body.removeAttribute("oncontextmenu");
      // document.body.style.zoom = null;
    };
  }, []);

const [loading, setLoading] = useState(false);
const [mbrMngId, setMbrMngId] = useState(null);

const dispatch = useDispatch()   

const { activeServices, revokedServices, recentServices } = useSelector(
  (state) => state.services
);
const isLegendSelected = useSelector((state) => state.legend.isLegendSelected);
  // 사용자 인증 함수
  const  auth = async (id) => {   
    setLoading(true);
    try { 
      const auth = await getAuthLogin(id)
      //  const auth = null
      if(!auth){
          alert("사용자 정보가 존재하지 않습니다.")
          return false
      }
      dispatch(login(auth))  
      setMbrMngId(auth.mbrMngId)
    } catch (error) {
      console.error("데이터 불러오기 중 오류 발생:", error);
    } finally {
      setLoading(false);
    }   
    return true
  }
  
  // // Redux 상태 변경 함수
  // const handleMobileListClick = () => {
  //   dispatch(setLegendSelected(false)); // Redux 상태 업데이트
  //   // console.log("Redux 상태 설정 완료 ",isLegendSelected);
  // };
//   const handleMobileListClick = () => {
//     console.log("Redux 상태 변경 전: ", store.getState().legend.isLegendSelected); 
//     dispatch(setLegendSelected(true)); // Redux 상태를 true로 설정
//     console.log("Redux 상태 변경 후: ", store.getState().legend.isLegendSelected); 
// };


// 데이터 Fetch
// useEffect(() => {
//   const { id } = queryString.parse(window.location.search);
//   if (_.isEmpty(mbrMngId) && !_.isEmpty(id)) {
//     auth(id); // 사용자 인증103873
//   }

/// 로컬 작업용- 시작23
useEffect(() => {
  const mockId = "MOCK_USER_ID"; // 목데이터 사용자 ID
  const { id } = queryString.parse("PT202408120000000004");

  if (_.isEmpty(mbrMngId)) {
    setMbrMngId(mockId); 
    
  }
/// 로컬 작업용 주석 - 꿑

  if (!_.isEmpty(mbrMngId)) {
    // Redux Thunk를 통해 데이터 가져오기
    dispatch(fetchActiveServices(mbrMngId))
    .unwrap()
    .then((data) => console.log("Active Services Fetched:", data))
    .catch((error) => console.error("Active Services Fetch Error:", error));
  
  dispatch(fetchRevokedServices(mbrMngId))
    .unwrap()
    .then((data) => console.log("Revoked Services Fetched:", data))
    .catch((error) => console.error("Revoked Services Fetch Error:", error));
  
  dispatch(fetchRecentServices(mbrMngId))
    .unwrap()
    .then((data) => console.log("Recent Services Fetched:", data))
    .catch((error) => console.error("Recent Services Fetch Error:", error));
  
  }

}, [mbrMngId]);
console.log("activeServices",activeServices);
if (_.isEmpty(mbrMngId)) {
  return <div></div>; // mbrMngId가 없으면 빈 화면
}


  if (loading) return <div>Loading...</div>;

  
  return (

    <Router>
      <div id="wrap">
        <Header />
        <main id="container">
            <div className="container container-main ">
              <Navbar />
              <div className="row">
                <Routes>
                  <Route
                    path="/"
                    element={
                      <MainPage
                        recentServices={recentServices}
                        activeServices={activeServices}
                        revokedServices={revokedServices}
                        isLegendSelected={isLegendSelected}
                        // setIsLegendSelected={setIsLegendSelected}
                        // handleMobileListClick={handleMobileListClick}
                      />
                    }
                  />
                  <Route path="/ThirdPartyDetail" element={<ThirdPartyDetailPage  />} />
                  <Route path="/Detail" element={<DetailPage  />} />
                </Routes>
              </div>
            </div>
        </main>
        <Footer />
      </div>
    </Router>
   
  
  );
}

export default App;