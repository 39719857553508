import React, { useState, useEffect } from "react";
import Card from "../Card/Card";
import FilterToolbar from "./FilterToolbar";
import Pagination from "./Pagination";
import "./CardSlider.css";
import useWindowSize from "../../hooks/useWindowSize";
import CardSwiper from "./CardSwiper";

export default function CardSlider({ cardsData, onBackClick }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState("recent");
  const [itemsPerPage, setItemsPerPage] = useState(2);
  const [isViewAll, setIsViewAll] = useState(false);
  const windowSize = useWindowSize();

  const totalCards = cardsData.length;
  const totalPages = Math.ceil(totalCards / itemsPerPage);

  useEffect(() => {
    // 화면 크기에 따라 itemsPerPag 설정
    if (isViewAll) {
      setItemsPerPage(6); // 펼쳐 보기일 경우 모든 카드 6개
    } else if (windowSize.width <= 767) {
      setItemsPerPage(totalCards);
    } else {
      setItemsPerPage(2); // 기본값은 2개
    }
  }, [windowSize.width, isViewAll, totalCards]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleViewAll = () => {
    setIsViewAll((prev) => !prev);
    // if (isViewAll) {
    //   setItemsPerPage(2);
    // } else {
    //   setItemsPerPage(6);
    // }
    setCurrentPage(1); // 첫 페이지로 이동
  };

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
    setCurrentPage(1); // 페이지를 첫 페이지로 리셋
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const formatDate = (dateString) => {
    return `${dateString.slice(0, 4)}-${dateString.slice(
      4,
      6
    )}-${dateString.slice(6, 8)}`;
  };

  const currentCards = cardsData
    .sort((a, b) => {
      const dateA = new Date(formatDate(a.last_consent_date));
      const dateB = new Date(formatDate(b.last_consent_date));
      return sortOrder === "recent" ? dateB - dateA : dateA - dateB;
    })
    .slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="card-slider-container">
      <FilterToolbar
        sortOrder={sortOrder}
        onBackClick={onBackClick}
        setSortOrder={handleSortOrderChange}
        handleViewAll={handleViewAll}
        isViewAll={isViewAll}
        isMobile={windowSize.width <= 767}
        isTablet={windowSize.width <= 1100}
      />

      <section className="card-slider-list-container">
        <div className="card-slider-list">
          {windowSize.width <= 767 ? (
            <CardSwiper
              cardsData={currentCards}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          ) : (
            // <div className="card-grid">
            <div
              className={`card-grid ${
                windowSize.width <= 1100 ? "centered-list" : ""
              }`}
            >
              {currentCards.map((card) => (
                <Card key={card.id} card={card} />
              ))}
            </div>
          )}
          <div>
            <Pagination
              currentPage={
                windowSize.width <= 767 ? currentIndex + 1 : currentPage
              }
              totalPages={
                windowSize.width <= 767 ? currentCards.length : totalPages
              }
              hideButtons={windowSize.width <= 767}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </section>
    </div>
  );
}
