// FilterToolbar.js
import React from "react";
import { Dropdown } from "react-bootstrap";
import SquareCenterIcon from "../../assets/square-center.svg";
import "./CardSlider.css";
import CustomButton from "../common/button/CustomButton";
import chevronleftIcon from "../../assets/chevron-left.svg";
export default function FilterToolbar({
  sortOrder,
  setSortOrder,
  handleViewAll,
  isViewAll,
  isMobile,
  isTablet,
  onBackClick,
  isRevokedTab,
}) {
  return (
    <div className="filter-toolbar">
      <div className="filter-left-container">
        {!isRevokedTab && (isMobile || isTablet) && (
          <CustomButton
            className="back-btn"
            variant="primary"
            label="뒤로가기"
            onClick={onBackClick}
          >
            <div className="filter-left-btn">
              {/* <span> */}
              <img src={chevronleftIcon} alt="info" />
              {/* </span> */}
              <p>돌아가기</p>
            </div>
          </CustomButton>
        )}
        {!isMobile && (
          <div className="filter-view-all custom-btn" onClick={handleViewAll}>
            <img src={SquareCenterIcon} alt="SquareCenter" />
            {isViewAll ? "펼쳐보기 접기" : "펼쳐보기"}
          </div>
        )}
      </div>
      <div className="filter-right-container">
        <Dropdown onSelect={(eventKey) => setSortOrder(eventKey)}>
          <Dropdown.Toggle
            variant="primary"
            id="dropdown-sort"
            className="custom-btn dropdown-btn"
          >
            {sortOrder === "recent" ? "최근 순" : "오래된 순"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="recent">최근 순</Dropdown.Item>
            <Dropdown.Item eventKey="old">오래된 순</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}
